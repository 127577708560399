import { isEmpty } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import { Accordion } from "components"
import { KIT_TYPE, getKitTypeInfo } from "domains/LeaderKit/utils"
import { SparklesIcon } from "icons/FontAwesomeIcons"
import {
  useSessionSurveyRollup,
  useSessionFeedbackSummary,
  useSessionFeedback,
  getSessionFeedback,
  useKitInstanceReportAIAccess,
} from "resources/monthly_kit"
import { useUser } from "resources/users"
import { AnswerDisplayContent, Button, Loading } from "ui"
import { useEffectAfterChange } from "ui/hooks"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"

const SessionSurveyReport = styled(function SessionSurveyReport({
  kitInstance,
  selectedKit,
  isJumbo,
  isJumboPromo,
  teamId,
  teamTags,
  accountId,
  accountTags,
  multiAccountIds,
  includeArchivedTeams,
  className,
}) {
  const kitInstanceId = kitInstance?.id
  const kitSlug = selectedKit?.slug
  const kitType = selectedKit?.type

  const { [FLAGS.SESSION_FEEDBACK_AI_SUMMARY]: showSessionFeedbackSummary } = useFeatures()
  const { data: sessionSurveyRollup, isFetching: isFetchingSessionSurveyRollup } = useSessionSurveyRollup({
    kitInstanceId,
    kitSlug,
    kitType,
    teamId,
    teamTags,
    accountId,
    accountTags,
    multiAccountIds,
    includeArchivedTeams,
  })

  const { data: aiAccess } = useKitInstanceReportAIAccess({
    enabled: !!showSessionFeedbackSummary,
    teamId,
    teamTags,
    accountId,
    accountTags,
    multiAccountIds,
    includeArchivedTeams,
  })
  const canAISummarize = !!aiAccess?.has_access && !!showSessionFeedbackSummary

  const {
    data: sessionFeedbackSummary,
    isInitialLoading: sessionFeedbackSummaryIsLoading,
    refetch: getSessionFeedbackSummary,
  } = useSessionFeedbackSummary({
    kitInstanceId,
    kitSlug,
    kitType,
    teamId,
    teamTags,
    accountId,
    accountTags,
    multiAccountIds,
    includeArchivedTeams,
    enabled: false,
  })
  const { data: initialSessionFeedback } = useSessionFeedback({
    kitInstanceId,
    kitSlug,
    kitType,
    teamId,
    teamTags,
    accountId,
    accountTags,
    multiAccountIds,
    includeArchivedTeams,
  })
  const { data: user } = useUser({ userId: "me" })
  const [sessionFeedback, setSessionFeedback] = useState()

  useEffectAfterChange(() => {
    if (initialSessionFeedback) {
      setSessionFeedback(initialSessionFeedback)
    }
  }, [initialSessionFeedback])

  const onLoadMoreSessionFeedback = async () => {
    const moreSessionFeedback = await getSessionFeedback({
      kitInstanceId,
      kitSlug,
      kitType,
      teamId,
      teamTags,
      accountId,
      accountTags,
      multiAccountIds,
      includeArchivedTeams,
      startIndex: sessionFeedback.start_index + sessionFeedback.page_size,
    })
    setSessionFeedback({
      ...moreSessionFeedback,
      feedback_list: [...sessionFeedback.feedback_list, ...moreSessionFeedback.feedback_list],
    })
  }

  if ((!sessionSurveyRollup && isFetchingSessionSurveyRollup) || !user) {
    return <Loading />
  }

  const surveys = kitInstance?.post_session_surveys?.filter((survey) => Object.values(survey).some((value) => value))

  if ((!surveys || surveys?.length === 0) && isEmpty(sessionSurveyRollup)) {
    return (
      <div className={className}>
        <h3 className="mb-medium text-gray-9">Post session survey responses</h3>
        No data
      </div>
    )
  }

  const kitTypeInfo = getKitTypeInfo(selectedKit?.type)
  const isMini = selectedKit?.type === KIT_TYPE.MINI

  const {
    team_count,
    completed_session_count,
    session_participant_count,
    unique_session_participant_count,
    response_count,
    feel_more_connected,
    work_more_effectively,
    learned_something_valuable,
    likelihood_of_recommending,
  } = sessionSurveyRollup

  return (
    <div className={className}>
      {!isMini && (
        <>
          <h2 className="mb-medium text-gray-9">Development and Connection Kits</h2>
          <div className="mb-medium">
            {!teamId && (
              <div>
                <span className="text-semi-bold">Teams:</span> {team_count}
              </div>
            )}
            <div>
              <span className="text-semi-bold">Sessions completed:</span> {completed_session_count}
            </div>
            <div>
              <span className="text-semi-bold">Session participants:</span> {session_participant_count}
            </div>
            <div>
              <span className="text-semi-bold">Unique session participants:</span> {unique_session_participant_count}
            </div>
            <div>
              <span className="text-semi-bold">Survey Responses:</span> {response_count}
            </div>
          </div>
          <h3 className="mb-medium text-gray-9">Post session survey responses</h3>
          <div>The first questions are on a 1-5 scale where 3 is neutral, 4 is agree, and 5 is strongly agree.</div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  Learned something valuable
                  <br />
                  (1-5)
                </th>
                {!isJumboPromo && (
                  <th>
                    Feel more connected
                    <br />
                    (1-5)
                  </th>
                )}
                {!!kitTypeInfo.showWorkMoreEffectivelyQuestion && !isJumbo && (
                  <th>
                    Work more effectively together
                    <br />
                    (1-5)
                  </th>
                )}
                <th>
                  Likelihood of recommending
                  <br />
                  (1-10)
                </th>
              </tr>
            </thead>
            <tbody>
              {!!surveys && (
                <>
                  {surveys.map((survey, idx) => (
                    <tr key={idx}>
                      <td className="text-nowrap">Response {idx + 1}</td>
                      <td>{survey.learned_something_valuable}</td>
                      {!isJumboPromo && <td>{survey.feel_more_connected}</td>}
                      {!!kitTypeInfo.showWorkMoreEffectivelyQuestion && !isJumbo && (
                        <td>{survey.work_more_effectively}</td>
                      )}
                      <td>{survey.likelihood_of_recommending}</td>
                    </tr>
                  ))}
                  <tr>
                    <th colSpan="5"></th>
                  </tr>
                </>
              )}
              <tr>
                <td className="text-nowrap">Median Score</td>
                <td>{learned_something_valuable?.median}</td>
                {!isJumboPromo && <td>{feel_more_connected?.median}</td>}
                {!!kitTypeInfo.showWorkMoreEffectivelyQuestion && !isJumbo && <td>{work_more_effectively?.median}</td>}
                <td>{likelihood_of_recommending?.median}</td>
              </tr>
              <tr>
                <td className="text-nowrap">Average Score</td>
                <td>{learned_something_valuable?.avg}</td>
                {!isJumboPromo && <td>{feel_more_connected?.avg}</td>}
                {!!kitTypeInfo.showWorkMoreEffectivelyQuestion && !isJumbo && <td>{work_more_effectively?.avg}</td>}
                <td>{likelihood_of_recommending?.avg}</td>
              </tr>
              <tr>
                <td className="text-nowrap">% Agree</td>
                <td>{!!learned_something_valuable?.pct_agree && `${learned_something_valuable.pct_agree}%`}</td>
                {!isJumboPromo && <td>{!!feel_more_connected?.pct_agree && `${feel_more_connected.pct_agree}%`}</td>}
                {!!kitTypeInfo.showWorkMoreEffectivelyQuestion && !isJumbo && (
                  <td>{!!work_more_effectively?.pct_agree && `${work_more_effectively.pct_agree}%`}</td>
                )}
                <td></td>
              </tr>
            </tbody>
          </table>

          <h3 className="mt-xl mb-medium text-gray-9">Open ended comments</h3>
          {!sessionFeedback ? (
            <Loading />
          ) : isEmpty(sessionFeedback.feedback_list) ? (
            "No data"
          ) : (
            <Accordion
              className="text-gray-9"
              title="Was there anything you especially liked about this session or thought could be improved?"
            >
              {!!canAISummarize && !!user.is_staff && (
                <div className="mb-large">
                  {!!sessionFeedbackSummary || !!sessionFeedbackSummaryIsLoading ? (
                    <>
                      <h4>
                        AI Generated Summary <SparklesIcon className="text-rising-yellow" />
                      </h4>
                      <div className="pl-large">
                        <AnswerDisplayContent
                          answer={
                            !!sessionFeedbackSummary
                              ? sessionFeedbackSummary?.improve_experience_feedback_summary
                              : "Generating..."
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <Button className="tertiary" onClick={() => getSessionFeedbackSummary()}>
                      Generate AI Summary <SparklesIcon className="text-rising-yellow" />
                    </Button>
                  )}
                </div>
              )}
              <div className="pl-large">
                {sessionFeedback.feedback_list.map(
                  (feedback, idx) => feedback && <AnswerDisplayContent key={idx} answer={feedback} />
                )}
                {sessionFeedback.start_index + sessionFeedback.page_size < sessionFeedback.total_count && (
                  <Button className="tertiary" onClick={onLoadMoreSessionFeedback}>
                    Load more
                  </Button>
                )}
              </div>
            </Accordion>
          )}
        </>
      )}
    </div>
  )
})`
  table {
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid var(--gray-7);
    padding: 4px 12px;
    text-align: center;
    color: var(--gray-9);
  }

  th {
    background-color: var(--gray-2);
  }

  tr {
    height: 36px;
  }
`

export default SessionSurveyReport
