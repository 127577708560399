import { useQueryClient } from "@tanstack/react-query"
import { Navigate } from "react-router-dom"

import ErrorPage from "./ErrorPage"
import SelectPayment from "./SelectPayment"
import UpdatePayment from "./UpdatePayment"

import { useCanPayOpenInvoice, useOwnedAccount } from "resources/billing"
import { meUserCacheKey, useUser } from "resources/users"
import { Loading } from "ui"

const SelectOrUpdatePayment = () => {
  const { data: user } = useUser({ userId: "me" })
  const queryClient = useQueryClient()
  const { data: ownedAccountOrNull, isFetching: ownedAccountIsFetching } = useOwnedAccount()
  const { data: canPayOpenInvoice, isFetching: canPayOpenInvoiceIsFetching } = useCanPayOpenInvoice(
    ownedAccountOrNull?.id
  )

  if (ownedAccountIsFetching || canPayOpenInvoiceIsFetching || !user) {
    return null
  }

  // If no owned account and user has an active account, redirect to home
  if (!ownedAccountOrNull && user.has_an_active_account) {
    return <Navigate to="/" replace />
  }

  if (canPayOpenInvoice?.user_has_an_active_account && !user.has_an_active_account) {
    // refetch the user to update has_an_active_account
    queryClient.refetchQueries({ queryKey: meUserCacheKey })
    return <Loading />
  }

  if (canPayOpenInvoice?.subscription_is_active && !user.has_an_active_account) {
    return <ErrorPage />
  }

  if (canPayOpenInvoice?.subscription_is_active) {
    return <Navigate to="/" replace />
  }

  if (canPayOpenInvoice?.can_pay) {
    return <UpdatePayment payInvoiceUrl={canPayOpenInvoice.pay_open_invoice_url} />
  } else if (!ownedAccountOrNull) {
    return <Navigate to="/code" replace />
  } else {
    return <SelectPayment />
  }
}

export default SelectOrUpdatePayment
