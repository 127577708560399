import { useQueryClient } from "@tanstack/react-query"
import cn from "classnames"
import { useCallback, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import { useKitSession } from "./KitSessionContext"

import { StepNavigation, StepNavigationBackButton } from "components/Steps/StepNavigation"
import renderKitBlocks from "domains/KitSession/blocks"
import { StepTitle, SyncSessionToggle } from "domains/KitSession/components"
import { useGroupTimer } from "domains/KitSession/GroupTimersContext"
import SessionHeader from "domains/KitSession/SessionHeader"
import SessionStepComponents from "domains/KitSession/SessionStepComponents"
import { applyTranslationLayer, isTranslatableKit } from "domains/KitSession/translation"
import { useSyncSessionEnabled } from "domains/KitSession/utils"
import { KIT_GROUP_SIZE } from "domains/LeaderKit/utils"
import { useHasTeamFeature } from "domains/Results/utils"
import getIconOrError from "icons"
import {
  setSessionCurrentStep,
  useCompleteKitSession,
  useKitParticipants,
  useRealtimeSessionCurrentStep,
} from "resources/monthly_kit"
import { useUser } from "resources/users"
import { Button, StepProgressBar, View } from "ui"
import { useEffectAfterChange, useWindowSize, useQueryParams } from "ui/hooks"
import { TEAM_FLAGS, SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import { buildUrl } from "utils/string"

const KitSessionStep = ({ sessionStep, currentStepIndex, allSessionSteps, isTeamLead }) => {
  const queryClient = useQueryClient()
  const theme = useCurrentTheme()
  const { kitInstance, team, preview } = useKitSession()
  const { isTabletOrLarger } = useWindowSize()
  const StepComponent = SessionStepComponents[STEP_TO_COMPONENT_MAP[sessionStep.component]]
  const { data: user } = useUser({ userId: "me" })
  const { enabled: syncSessionTeamFeatureEnabled } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_SESSION_AUTO_FOLLOW)
  const syncSessionFeatureEnabled = syncSessionTeamFeatureEnabled && !isTeamLead
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { enabled: realtimeTimerEnabled, isInitialLoading: realtimeTimerFeatureLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_REALTIME_ACTIVITY_TIMER
  )
  const { data: { team_member_step: syncSessionStep = null } = {} } = useRealtimeSessionCurrentStep({
    kitInstanceId: kitInstance?.id,
    enabled: !!syncSessionFeatureEnabled,
  })
  const navigate = useNavigate()
  const syncSessionEnabled = useSyncSessionEnabled()
  const syncSessionValid = syncSessionFeatureEnabled && syncSessionStep
  const inSamePage = syncSessionStep && syncSessionStep.path === sessionStep.path
  const inSameDetachGroup = syncSessionStep && syncSessionStep.detach_group === sessionStep.detach_group
  const syncSessionStepNavigable =
    syncSessionStep && !inSamePage && (!syncSessionStep.is_detached || !inSameDetachGroup)
  const sessionStepNavigable = !sessionStep.is_detached || (sessionStep.is_detached_end && syncSessionStepNavigable)
  const syncSessionShouldNavigate = syncSessionValid && syncSessionEnabled && sessionStepNavigable

  const navigateToLead = useCallback(() => {
    // If lead step is in detached group, navigate to the start of the group
    // Don't navigate to start if lead is on hang tight page, unless participant
    // has just joined the session
    if (syncSessionStep.is_detached && (!syncSessionStep.is_detached_edge || currentStepIndex === 0)) {
      navigate(`../${syncSessionStep.detach_group_start_path}`)
    } else {
      navigate(`../${syncSessionStep.path}`)
    }
  }, [syncSessionStep, navigate, currentStepIndex])

  useEffectAfterChange(() => {
    if (syncSessionShouldNavigate) {
      navigateToLead()
    }
  }, [syncSessionShouldNavigate, navigateToLead])

  const onEnableSyncSession = () => {
    // We use syncSessionStepNavigable rather than syncSessionShouldNavigate here
    // because the participant is explicitly enabling page-sync
    if (syncSessionStepNavigable) {
      navigateToLead()
    }
  }

  const breadcrumbs = !sessionStep.progress_bar_group ? null : (
    <KitSessionBreadcrumbs
      kitInstance={kitInstance}
      sessionStep={sessionStep}
      allSessionSteps={allSessionSteps}
      preview={preview}
    />
  )
  const { mutateAsync: completeKitSession } = useCompleteKitSession()
  const [sessionCompleteFired, setSessionCompleteFired] = useState()

  const { timer_group, auto_start_timer } = sessionStep
  const {
    groupTimer,
    updateGroupTimer,
    isInitialLoading: groupTimerIsInitialLoading,
  } = useGroupTimer(timer_group, {
    realtimeGetEnabled: realtimeTimerEnabled,
    realtimeSetEnabled: realtimeTimerEnabled && isTeamLead,
  })
  const [initializedTimerGroup, setInitializedTimerGroup] = useState(null)
  const groupTimerInitializing = auto_start_timer && timer_group && initializedTimerGroup !== timer_group
  useEffectAfterChange(() => {
    if (!groupTimerIsInitialLoading && !realtimeTimerFeatureLoading) {
      if (auto_start_timer && groupTimer && !groupTimer.isRunning && !groupTimer.complete && groupTimerInitializing) {
        updateGroupTimer({
          isRunning: true,
          playClickedTime: Date.now(),
          timePassed: 0,
          complete: false,
        })
        setInitializedTimerGroup(timer_group)
      }
    }
  }, [
    sessionStep,
    groupTimer,
    updateGroupTimer,
    auto_start_timer,
    groupTimerIsInitialLoading,
    realtimeTimerFeatureLoading,
    groupTimerInitializing,
    timer_group,
  ])

  const kitInstanceId = kitInstance.id
  const shouldSetSessionCompleted =
    !kitInstance.session_completed_at && isTeamLead && !preview && currentStepIndex >= allSessionSteps.length - 1

  useEffectAfterChange(() => {
    if (shouldSetSessionCompleted && !sessionCompleteFired) {
      setSessionCompleteFired(true)
      completeKitSession({ kitInstanceId })
    }
  }, [shouldSetSessionCompleted, completeKitSession, kitInstanceId, sessionCompleteFired])

  // TODO(hackathon,kit-translation,evnp): Clean up code below after hackathon
  // TRANSLATION LAYER MANAGEMENT
  const { lang } = useQueryParams()
  const kitSlug = kitInstance?.kit?.slug
  const { data: participants } = useKitParticipants({
    kitInstance,
    // Don't enable this query unless translation functionality is active:
    enabled: !!(team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION) && lang && isTranslatableKit(kitSlug)),
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  useEffectAfterChange(() => {
    if (
      // FEATURE-FLAG KIT TRANSLATION (code should be no-op for other users):
      team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION) &&
      user &&
      !groupTimerInitializing &&
      // TODO(hackathon,kit-translation,evnp) Only try to apply translations for psych-safety kit for now:
      isTranslatableKit(kitSlug) &&
      // Wait for participants to load before applying
      // translation so we catch the "hang tight" text:
      participants &&
      !user.email.endsWith("@rtunittest.com")
      // don't apply translation layers during end-to-end session tests, otherwise
      // they take forever to run and we don't get clean translation input data
    ) {
      applyTranslationLayer(kitSlug, lang, isTeamLead)
    }
  }, [lang, currentStepIndex, isTeamLead, user, groupTimerInitializing, participants, kitSlug, team])
  // END TRANSLATION LAYER MANAGEMENT

  // Since the useEffectAfterChange fires after render, this guarantees that GroupTimers
  // have the correct data
  if (!user || groupTimerInitializing) {
    return null
  }

  const StepTitleIcon = sessionStep.step_icon && getIconOrError(sessionStep.step_icon)
  const renderStepTitleContent = () => {
    const HeadingTag = isTabletOrLarger ? "h1" : "h2"
    return (
      <HeadingTag className={sessionStep.step_css?.h1 ?? "text-gray-9"}>
        {renderKitBlocks(sessionStep.title, { kitInstance, sessionStep, user, isTeamLead, preview })}
      </HeadingTag>
    )
  }

  const isLastSessionStep = currentStepIndex === allSessionSteps.length - 1

  const navigateNextHandler = () => {
    const nextStepPath = allSessionSteps[currentStepIndex + 1]?.path
    if (!preview && nextStepPath && isTeamLead) {
      setSessionCurrentStep({
        kitInstanceId: kitInstance.id,
        currentStep: nextStepPath,
        queryClient,
      })
    }
  }

  const navigateBackHandler = () => {
    const backStepPath = allSessionSteps[currentStepIndex - 1]?.path
    if (!preview && backStepPath && isTeamLead) {
      setSessionCurrentStep({
        kitInstanceId: kitInstance.id,
        currentStep: backStepPath,
        queryClient,
      })
    }
  }
  const hideNextButton =
    !isTeamLead &&
    !!syncSessionFeatureEnabled &&
    !!syncSessionEnabled &&
    (!sessionStep.is_detached || !!sessionStep.is_detached_end || currentStepIndex === 0)
  const hideBackButton =
    !isTeamLead &&
    !!syncSessionFeatureEnabled &&
    syncSessionEnabled &&
    (!sessionStep.is_detached || !!sessionStep.is_detached_start)
  const syncSessionToggleContent = !isTeamLead && !!syncSessionFeatureEnabled && (
    <SyncSessionToggle onEnable={onEnableSyncSession} />
  )
  const showTimerControls = sessionStep.show_timer_controls

  return (
    <View $flexDirection="column" className="main-container">
      <SessionHeader
        kitInstance={kitInstance}
        allSessionSteps={allSessionSteps}
        className={cn({
          "mb-xl": isTabletOrLarger && !theme.hideHeader,
          "mb-small": isTabletOrLarger && theme.hideHeader,
        })}
        preview={preview}
        isTeamLead={isTeamLead}
      />

      {!!sessionStep.title && (
        <StepTitle
          sessionStep={sessionStep}
          className={sessionStep.step_css?.title ?? ""}
          breadcrumbs={breadcrumbs}
          showTimerControls={showTimerControls}
          isTeamLead={isTeamLead}
        >
          {StepTitleIcon ? (
            <View $alignItems="center">
              <StepTitleIcon className={sessionStep.step_css?.icon ?? "mr-small fa-2x"} />
              {renderStepTitleContent()}
            </View>
          ) : (
            renderStepTitleContent()
          )}
        </StepTitle>
      )}
      <StepComponent
        kitInstance={kitInstance}
        sessionStep={sessionStep}
        preview={preview}
        isTeamLead={isTeamLead}
        breadcrumbs={breadcrumbs}
        navigateNextHandler={navigateNextHandler}
        navigateBackHandler={navigateBackHandler}
        nextButtonProps={{ hide: hideNextButton }}
        backButtonProps={{ hide: hideBackButton }}
        navRightContent={syncSessionToggleContent}
      />
      {!!isLastSessionStep && !sessionStep.custom_step_navigation && (
        <KitSessionFinishButton
          kitInstance={kitInstance}
          preview={preview}
          sessionRealtimeUpdates={sessionRealtimeUpdatesV2}
        />
      )}
      {!isLastSessionStep && !sessionStep.custom_step_navigation && (
        <StepNavigation
          nextText={sessionStep.next_button_text ?? null}
          onNext={navigateNextHandler}
          onBack={navigateBackHandler}
          nextButtonProps={{ hide: hideNextButton }}
          backButtonProps={{ hide: hideBackButton }}
          rightContent={syncSessionToggleContent}
        />
      )}
      <FooterBlocks
        footer_blocks={kitInstance.kit.footer_blocks}
        sessionStep={sessionStep}
        preview={preview}
        isTeamLead={isTeamLead}
        user={user}
      />
    </View>
  )
}

const FooterBlocks = ({ footer_blocks, kitInstance, sessionStep, user, isTeamLead, preview }) => {
  if (sessionStep.hide_footer_blocks || !footer_blocks) {
    return null
  }

  return (
    <View $justifyContent="center">
      {renderKitBlocks(footer_blocks, { kitInstance, sessionStep, user, isTeamLead, preview })}
    </View>
  )
}

const KitSessionBreadcrumbs = ({ kitInstance, sessionStep, allSessionSteps, preview }) => {
  const urlQueryParams = preview ? { preview } : {}
  const getStepUrl = (step) => buildUrl([kitInstance.session_url, step.path], { urlQueryParams })
  const progressBarSteps = allSessionSteps.filter((s) => s.progress_bar_group === sessionStep.progress_bar_group)

  return (
    <StepProgressBar
      className="mb-medium mb-xxs-mobile-only mt-large-mobile-only"
      currentStep={sessionStep}
      allSteps={progressBarSteps}
      getStepUrl={getStepUrl}
    />
  )
}

const KitSessionFinishButton = styled(function KitSessionFinishButton({
  kitInstance,
  preview,
  sessionRealtimeUpdates,
  className,
}) {
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates })
  const { data: user } = useUser({ userId: "me" })

  if (!user) {
    return null
  }

  const { buttonText, buttonUrl } = getFinishButtonInfo({ preview, kitInstance, participants })
  const isJumbo = kitInstance.kit.group_size === KIT_GROUP_SIZE.JUMBO

  const finishButton = (
    <Button
      className={cn(className, "finish-button nav-button", {
        "mt-xxxl": !isJumbo,
      })}
      as={Link}
      to={buttonUrl}
    >
      {buttonText}
    </Button>
  )

  return isJumbo ? (
    <View className="mt-xxxl">
      <StepNavigationBackButton />
      {finishButton}
    </View>
  ) : (
    finishButton
  )
})`
  min-width: 200px;
`

const getFinishButtonInfo = ({ preview, kitInstance, participants = null }) => {
  if (preview || (Array.isArray(participants) && participants.length <= 1)) {
    return {
      buttonText: "Finish",
      buttonUrl: kitInstance.home_url,
    }
  }

  const kit = kitInstance.kit
  if (kit.finish_button) {
    const url = (() => {
      switch (kit.finish_button.destination) {
        case "minis":
          return buildUrl(["/minis"], { urlQueryParams: { team_id: kitInstance.team_id } })
        case "kit_results":
        default:
          return kitInstance.results_url
      }
    })()
    return {
      buttonText: kit.finish_button.text,
      buttonUrl: url,
    }
  }
  if (kitInstance.kit.bonus_page) {
    return {
      buttonText: "Unlock my bonus!",
      buttonUrl: kitInstance.bonus_url,
    }
  }

  return {
    buttonText: "Finish",
    buttonUrl: kitInstance.results_url,
  }
}

const STEP_TO_COMPONENT_MAP = {
  KIT_BLOCK_STEP: "KitBlockStep",
  ACTIVITY_INTRO_STEP: "ActivityIntroStep",
  TAKE_EXERCISE: "TakeExerciseStep",
  TAKE_A_BREAK: "TakeABreakStep",
  REFLECTIONS_RESULTS: "ReflectionsResultsStep",
  FEEDBACK: "FeedbackStep",

  TALENTS_REVIEW: "TalentsReviewStep",
  TALENTS_SHARE: "TalentsShareStep",
  TALENTS_MASHUP: "TalentsMashupStep",
  TALENTS_OVERVIEW: "TalentsOverviewStep",

  EXPECTATIONS_CLARIFY_REQUEST_STEP: "ExpectationsClarifyRequestStep",
  EXPECTATIONS_ROAD_DISCUSSION: "ExpectationsRoadDiscussionStep",

  CAREER_HORIZONS_THINKING_BIG_SHARE: "CareerHorizonsThinkingBigShareStep",
  CAREER_HORIZONS_CHARTING_PATH_SHARE: "CareerHorizonsChartingPathShareStep",

  FEEDBACK_FLEX_SELECT_TEAM_LEAD: "FeedbackFlexSelectTeamLead",
  FEEDBACK_FLEX_SELECT_TEAM_MEMBER: "FeedbackFlexSelectTeamMember",
  FEEDBACK_FLEX_PREPARE: "FeedbackFlexPrepare",
  FEEDBACK_FLEX_SHARE: "FeedbackFlexShareStep",
  FEEDBACK_DELIVERING_DELIVER: "FeedbackDeliveringDeliverStep",

  LEARNER_AGILITY_LOOKING_BACK_SHARE: "LearnerAgilityLookingBackShareStep",
  LEARNER_AGILITY_BEHAVIORS_SHARE: "LearnerAgilityBehaviorsShareStep",
  LEARNER_AGILITY_LEARNING_PREFERENCES_INTRO_BEGIN: "LearnerAgilityLearningPreferencesIntroBeginStep",
  LEARNER_AGILITY_LEARNING_PREFERENCES_REVIEW: "LearnerAgilityLearningPreferencesReviewStep",
  LEARNER_AGILITY_LEARNING_PREFERENCES_SHARE_STEP: "LearnerAgilityLearningPreferencesShareStep",

  LIFELINES_INTRO_KICKOFF: "LifelinesIntroKickoffStep",

  CRISIS_RESPONSE_PREVIOUS_CRISIS_SHARE: "CrisisResponsePreviousCrisisShareStep",
  CRISIS_RESPONSE_RESPONDER_TYPE: "CrisisResponseResponderTypeStep",
  CRISIS_RESPONSE_SCENARIO_INTRO: "CrisisResponseScenarioIntroStep",
  CRISIS_RESPONSE_SUMMARY: "CrisisResponseSummaryStep",
  CRISIS_RESPONSE_SCENARIO_THINK_TEAM_LEAD_STEP: "CrisisResponseScenarioThinkTeamLeadStep",
  CRISIS_RESPONSE_SCENARIO_THINK_TEAM_MEMBER_STEP: "CrisisResponseScenarioThinkTeamMemberStep",
  CRISIS_RESPONSE_SCENARIO_ASSIGN_TEAM_LEAD_STEP: "CrisisResponseScenarioAssignTeamLeadStep",
  CRISIS_RESPONSE_SCENARIO_ASSIGN_TEAM_MEMBER_STEP: "CrisisResponseScenarioAssignTeamMemberStep",
  CRISIS_RESPONSE_SCENARIO_IDENTIFY: "CrisisResponseScenarioIdentifyStep",
  CRISIS_RESPONSE_SCENARIO_RESULT: "CrisisResponseScenarioResultStep",

  FIVE_SENSES_SHARE: "FiveSensesShareStep",
  FIVE_SENSES_INTRO_KICKOFF: "FiveSensesIntroKickoffStep",

  KEEP_LET_GO_INTRO_KICKOFF: "KeepLetGoIntroKickoffStep",
  KEEP_LET_GO_SHARE: "KeepLetGoShareStep",
}

export default KitSessionStep
