import { isAfter, parseISO, subHours } from "date-fns"
import { useState } from "react"
import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom"

import { GroupTimersContext, getInitialGroupTimers } from "./GroupTimersContext"
import { KitSessionContext } from "./KitSessionContext"
import KitSessionStep from "./KitSessionStep"

import { usePreviewOverlayContext } from "components/PreviewOverlayContext"
import RouteSteps from "components/Steps"
import { KIT_GROUP_SIZE } from "domains/LeaderKit/utils"
import { useHasTeamFeature } from "domains/Results/utils"
import { useRegenerateKitSessionCode, useJoinKitInstanceById } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import { Button, Loading, PageTitle, View } from "ui"
import { useEffectAfterChange, useQueryParams, useWindowSize } from "ui/hooks"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useModal } from "ui/ModalContext"
import { useKitInstanceByID } from "ui/SelectedTeamContext"
import { useUpdateTheme } from "ui/ThemeUpdateContext"

const KitSessionContent = ({ kitInstance, visibleSteps, preview, team, isTeamLead }) => {
  const [groupTimers, setGroupTimers] = useState(getInitialGroupTimers(visibleSteps))
  const { enabled: syncSessionTeamFeatureEnabled, isInitialLoading: syncSessionTeamFeatureIsInitialLoading } =
    useHasTeamFeature(team, SHARED_FLAGS.RTDEV_SESSION_AUTO_FOLLOW)
  const syncSessionFeatureEnabled = syncSessionTeamFeatureEnabled && !isTeamLead
  const wildcardStep = getWildcardStep({ kitInstance, team, visibleSteps, preview, syncSessionFeatureEnabled })
  const { enableWidescreen, enableSessionTheme, resetTheme } = useUpdateTheme()
  const [themeIsUpdating, setThemeIsUpdating] = useState(true)
  const { isDesktopOrLarger } = useWindowSize()

  useEffectAfterChange(() => {
    if (kitInstance.kit.group_size === KIT_GROUP_SIZE.JUMBO && isTeamLead && isDesktopOrLarger) {
      enableWidescreen()
    } else {
      enableSessionTheme()
    }
    setThemeIsUpdating(false)
    return () => resetTheme()
  }, [kitInstance.kit.group_size, isTeamLead, isDesktopOrLarger, enableWidescreen, enableSessionTheme, resetTheme])

  if (themeIsUpdating || syncSessionTeamFeatureIsInitialLoading) {
    return null
  }

  return (
    <KitSessionContext.Provider value={{ kitInstance, visibleSteps, preview, team }}>
      <GroupTimersContext.Provider value={{ groupTimers, setGroupTimers }}>
        <RouteSteps>
          {visibleSteps.map((step, currentStepIndex) => (
            <Route
              path={step.path}
              key={step.path}
              element={
                <KitSessionStep
                  sessionStep={step}
                  currentStepIndex={currentStepIndex}
                  allSessionSteps={visibleSteps}
                  isTeamLead={isTeamLead}
                />
              }
            />
          ))}
          <Route
            path="*"
            ignore
            element={<Navigate to={wildcardStep.path + (preview ? "?preview=true" : "")} replace />}
          />
        </RouteSteps>
      </GroupTimersContext.Provider>
    </KitSessionContext.Provider>
  )
}

const getWildcardStep = ({ kitInstance, visibleSteps, preview, syncSessionFeatureEnabled }) => {
  if (preview || !kitInstance.session_current_step || !!syncSessionFeatureEnabled) {
    return visibleSteps[0]
  }

  return visibleSteps.find((step) => step.path === kitInstance.session_current_step) ?? visibleSteps[0]
}

const ExitPreviewModeModal = ({ kitInstance, closeModal }) => {
  const navigate = useNavigate()

  const onExitClick = async () => {
    navigate(kitInstance.home_url)
    closeModal()
  }

  return (
    <>
      <div className="space-y-medium mb-large">
        You will return to the theme introduction page after exiting preview mode.
      </div>
      <View>
        <Button className="secondary" onClick={onExitClick}>
          Exit
        </Button>
        <Button className="link secondary" onClick={closeModal}>
          Cancel
        </Button>
      </View>
    </>
  )
}

export default function KitSession() {
  const { data: user } = useUser({ userId: "me" })
  const { id } = useParams()
  const { kitInstance, isFetching: kitInstanceIsFetching } = useKitInstanceByID(id)
  const { data: team } = useTeam({ teamId: kitInstance?.team_id })
  const { mutateAsync: regenerateKitSessionCode } = useRegenerateKitSessionCode()
  const { mutateAsync: joinKitInstanceById } = useJoinKitInstanceById()
  const preview = useQueryParams()["preview"] === "true"
  const { setPreviewOverlay, setPreviewOverlayText, setOnPreviewExitClick } = usePreviewOverlayContext()
  const { setModal } = useModal()
  const previewOverlayText = team?.preview
    ? "Use preview mode to review or prepare for a team session. To run a team session with " +
      "participants, assign yourself as a team lead in the Admin section."
    : "Use preview mode to review or prepare for a team session. When you are ready " +
      "to run the session with participants, press the Start session button on the Kits page."

  useEffectAfterChange(() => {
    setPreviewOverlay(preview)
    setPreviewOverlayText(previewOverlayText)
    setOnPreviewExitClick(() => async () => {
      setModal({
        title: "Exit preview mode?",
        content: <ExitPreviewModeModal kitInstance={kitInstance} closeModal={() => setModal(null)} />,
      })
    })

    return function cleanup() {
      setPreviewOverlay(false)
      setPreviewOverlayText(null)
      setOnPreviewExitClick(null)
    }
  }, [
    preview,
    previewOverlayText,
    setPreviewOverlay,
    setPreviewOverlayText,
    setOnPreviewExitClick,
    kitInstance,
    setModal,
  ])

  useEffectAfterChange(() => {
    const ensureSessionCode = async (kitInstanceId) => await regenerateKitSessionCode({ kitInstanceId })

    if (kitInstance && user && team) {
      if (!kitInstance.session_code && team.team_lead_id === user.id && !team.preview && !preview) {
        ensureSessionCode(kitInstance.id)
      }
    }
  }, [kitInstance, user, team, preview, regenerateKitSessionCode])

  useEffectAfterChange(() => {
    const asyncJoinKitInstanceById = async (kitInstanceId) => await joinKitInstanceById({ kitInstanceId })
    if (kitInstance && !kitInstance.is_participant && !kitInstance.is_session_full) {
      asyncJoinKitInstanceById(kitInstance.id)
    }
  }, [kitInstance, joinKitInstanceById])

  if (!kitInstance) {
    return kitInstanceIsFetching ? <Loading /> : <Navigate to="/code" />
  }

  if (!user || !team) {
    return null
  }

  if (!kitInstance.is_participant && kitInstance.is_session_full) {
    return (
      <div className="p-large">
        Session is currently full. Please have your team lead <Link to="/contact">contact us</Link> if you'd like to
        join.
      </div>
    )
  }

  if (!team.demo && !kitInstance.is_participant) {
    return null
  }

  if (kitInstance.session_completed_at) {
    const oneHourAgo = subHours(new Date(), 1)
    if (isAfter(oneHourAgo, parseISO(kitInstance.session_completed_at))) {
      return <Navigate to={kitInstance.home_url} replace />
    }
  }

  const isTeamLead = team.team_lead_id === user.id

  const visibleSteps = kitInstance.kit.session.steps.filter((step) => {
    if (step.team_lead_only && !isTeamLead) {
      return false
    }
    if (step.team_member_only && isTeamLead) {
      return false
    }
    if (step.skip_if_preview && preview) {
      return false
    }

    return true
  })

  return (
    <>
      <PageTitle>Session</PageTitle>
      <KitSessionContent
        kitInstance={kitInstance}
        visibleSteps={visibleSteps}
        preview={preview}
        team={team}
        isTeamLead={isTeamLead}
      />
    </>
  )
}
