import { StorageKey } from "utils/storage"
import { ordinal } from "utils/string"

const DEFAULT_IMPORT_RELOAD_DELAY_MS = 200
const DEFAULT_IMPORT_RELOAD_BLOCK_MS = 60_000
const DEFAULT_NUM_IMPORT_RETRIES = 4
const DEFAULT_IMPORT_RETRY_EXPONENTIAL_BACKOFF_START_MS = 100

// Diagnostic util for logging retry/reload events during import error handling sequence:
function _dynamicImportLog(...messages: Array<string | number | null>): void {
  const text = messages
    .filter(Boolean)
    .map((message) => message?.toString())
    .join(" ")
    .replace(/\s+/, " ")
  console.warn(`[dynamic import] ${text}`)
}

// From an import function of the type that is passed to safeDynamicImport,
// parse the path of the module import used. For example:
// () => import("./my/module")  -->  "./my/module"
// In practice, Vite will rewrite "./my/module" to something else, depending on env.
// This is why we need to extract this information from an import function, instead
// of making safeDynamicImport accept a module path string and using that for retries.
function parsePathFromImportFunction(importFn: () => any): string | null {
  return importFn.toString().match(/import\("([a-zA-Z0-9./?=_-]+)"\)/)?.[1] ?? null
}

// Check whether the result of a dynamic import contains all entries we expect. This is
// a more reliable way of detecting failed imports than catching errors or using Vite's
// vite:preloadError (see: https://vitejs.dev/guide/build.html#load-error-handling).
function isImportResultValid(result: Record<string, unknown> | null, expectedKeys: string[]): boolean {
  return !!result && expectedKeys.every((key) => result?.[key])
}

// Perform a dynamic module import. If any of the following conditions occur, attempt
// a series of import retries and reload the page once if necessary, to try and
// resolve the failed import (likely caused by some kind of network issue).
// - Error during dynamic import.
// - No error, but dynamic import returned null/undefined instead of a module.
// - No error, but imported module didn't contain an expected sub-import specified
//   in the provided expectedKeys array.
//
// Dynamic import retries and page reload will occur in this sequence by default:
// 1. Attempt initial dynamic import.
// 2. If import fails, retry 4 times with exponential-backoff delays in between:
//    wait 100ms > retry > wait 200ms > retry > wait 400ms > retry > wait 800ms > retry
// 3. If all retries fail, force a reload of the entire page once. No more than one
//    page reload will ever occur within 1 minute.
// 4. After page reload, re-attempt initial dynamic import.
// 5. If import fails, retry 4 times with exponential-backoff delays, as above.
// 6. If all retries fail, raise an error to keep us aware of volume of import failures.
async function safeDynamicImport<T extends Record<string, unknown>>(
  importFn: () => Promise<T>,
  expectedKeys: string[] = [],
  {
    retries = DEFAULT_NUM_IMPORT_RETRIES,
    retryDelayExponentialBackoffStartMs = DEFAULT_IMPORT_RETRY_EXPONENTIAL_BACKOFF_START_MS,
    delayReloadMs = DEFAULT_IMPORT_RELOAD_DELAY_MS,
    blockReloadMs = DEFAULT_IMPORT_RELOAD_BLOCK_MS,
  } = {}
): Promise<T | null> {
  let result: T | null = null
  let importError: Error | null = null

  // Attempt initial dynamic import:
  try {
    result = await importFn()
  } catch (error) {
    importError = error as Error
  }

  // ONLY if import result is invalid, enter retry & reload sequence:
  if (!isImportResultValid(result, expectedKeys)) {
    const importPath = parsePathFromImportFunction(importFn)

    // Retry dynamic import with default parameters resulting in this sequence:
    // wait 100ms > retry > wait 200ms > retry > wait 400ms > retry > wait 800ms > retry
    try {
      result = await _retryDynamicImport<T>(importPath, expectedKeys, { retries, retryDelayExponentialBackoffStartMs })
    } catch (error) {
      importError = error as Error
    }

    if (!isImportResultValid(result, expectedKeys)) {
      // If import retries failed, force a full page reload only once.
      // Afterward, import retry sequence will occur again if initial dynamic import
      // fails again, but we won't reload the page again.
      const willReload = _reloadPageOnceOnDynamicImportFailure({ delayReloadMs, blockReloadMs })

      if (willReload) {
        _dynamicImportLog(
          importPath,
          "import failed, will attempt to resolve by reloading page",
          `once after ${delayReloadMs}ms wait`
        )
      } else {
        // If we didn't reload the page and all import retries failed, re-throw error:
        const missingKeys = expectedKeys.filter((key) => !result?.[key])
        const missingMsg = missingKeys.length ? ` (missing ${missingKeys.join(", ")})` : ""
        throw (
          importError ??
          new Error(`Failed to dynamically import module${missingMsg}: ${importPath ?? importFn.toString()}`)
        )
      }
    }
  }

  return result ?? null
}

async function _retryDynamicImport<T>(
  importPath: string | null,
  expectedKeys: string[] = [],
  {
    retries = DEFAULT_NUM_IMPORT_RETRIES,
    retryDelayExponentialBackoffStartMs = DEFAULT_IMPORT_RETRY_EXPONENTIAL_BACKOFF_START_MS,
  } = {}
): Promise<T | null> {
  if (!importPath) {
    return null
  }

  let result = null
  let importError = null
  let numTimesRetried = 0
  let delayMs = retryDelayExponentialBackoffStartMs

  while (!isImportResultValid(result, expectedKeys) && numTimesRetried < retries) {
    numTimesRetried++

    _dynamicImportLog(
      importPath,
      "import failed after page reload, will attempt",
      ordinal(numTimesRetried),
      `retry after ${delayMs}ms wait`
    )

    // Wait appropriate amount of time for exponential backoff of import retries.
    // By default, retry sequence will be:
    // import > 100ms > retry > 200ms > retry > 400ms > retry > 800ms > retry > error
    const waitMs = delayMs
    await new Promise((resolve) => setTimeout(resolve, waitMs))
    delayMs *= 2

    // Add a timestamp query param to the path to bypass the browser's default
    // behavior of caching import failure responses and returning those again.
    // See: https://github.com/whatwg/html/issues/6768
    const separator = importPath.includes("?") ? "&" : "?"
    const timestampedPath = `${importPath}${separator}t=${new Date().getTime()}`

    // Retry dynamic import:
    try {
      result = await import(/* @vite-ignore */ timestampedPath)
    } catch (error) {
      importError = error as Error
    }
  }

  if (!isImportResultValid(result, expectedKeys)) {
    // If all import retries failed, re-throw error:
    throw importError
  } else {
    // Otherwise, only in cases where there was an initial import failure,
    // log successful import and retry/reload details that led to it:
    const lastReloadMs = _getLastReloadTimeMs()
    if (numTimesRetried && lastReloadMs) {
      _dynamicImportLog(
        importPath,
        "import successful during",
        ordinal(numTimesRetried),
        "retry (after a forced page reload",
        `${new Date().getTime() - lastReloadMs}ms ago, preceeded by`,
        DEFAULT_NUM_IMPORT_RETRIES,
        "earlier retries)"
      )
    } else if (numTimesRetried) {
      _dynamicImportLog(
        importPath,
        "import successful during",
        ordinal(numTimesRetried),
        "retry (no forced page reload needed)"
      )
    }
  }

  return result ?? null
}

// Force-refresh the page if an error occurs loading a dynamic imports.
// We will ever only reload page once in this way to prevent edge-case reload loops.
let isForcedReloadScheduled = false
function _reloadPageOnceOnDynamicImportFailure({
  delayReloadMs = DEFAULT_IMPORT_RELOAD_DELAY_MS,
  blockReloadMs = DEFAULT_IMPORT_RELOAD_BLOCK_MS,
}: {
  delayReloadMs?: number
  blockReloadMs?: number
} = {}): boolean {
  // Only trigger another reload if one isn't already pending from another
  // concurrent safeDynamicImport call. Still return true if another reload
  // is pending, to indicate correctly to callers that reload will occur soon.
  if (isForcedReloadScheduled) {
    return true
  }

  const nowMs = new Date().getTime()
  const lastReloadMs = _getLastReloadTimeMs()

  // Reload page if no forced reload happened in last minute (avoid reload loops):
  if (!lastReloadMs || nowMs - lastReloadMs > blockReloadMs) {
    isForcedReloadScheduled = true
    _setLastReloadTimeMs()
    // Delay reload slightly to increase chances of resolving import issues:
    // (eg. if CDN was momentarily down, more likely to be back after small wait)
    setTimeout(() => window.location.reload(), delayReloadMs)
    return true
  }

  return false
}

function _getLastReloadTimeMs(): number | null {
  const time = parseInt(sessionStorage.getItem(StorageKey.LastForcedReloadTimeMs) ?? "")
  return Number.isInteger(time) ? time : null
}

function _setLastReloadTimeMs(): void {
  sessionStorage.setItem(StorageKey.LastForcedReloadTimeMs, new Date().getTime().toString())
}

function _clearLastReloadTimeMs(): void {
  sessionStorage.removeItem(StorageKey.LastForcedReloadTimeMs)
}

// Clean up any prior force-reload record from session storage after 1 minute:
setTimeout(_clearLastReloadTimeMs, DEFAULT_IMPORT_RELOAD_BLOCK_MS)

export { safeDynamicImport, isImportResultValid, parsePathFromImportFunction }
