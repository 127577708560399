import cn from "classnames"
import { useField } from "formik"
import { debounce } from "lodash-es"
import { useCallback, type Ref, type ReactNode } from "react"
import Select, {
  components as reactSelectSubComponents,
  type Props as ReactSelectProps,
  type SelectInstance,
  type ControlProps,
  type SingleValueProps,
  type ActionMeta,
  type InputActionMeta,
} from "react-select"
import AsyncSelect from "react-select/async"
import { styled } from "styled-components"
import type { NonNegativeInteger } from "type-fest"

import getIconOrError from "icons"
import { formatLength } from "ui/utils"

interface SelectOption {
  value: string
  label: string
  tags?: string[]
  icon?: string
  iconClassName?: string
  formattedSelectedLabel?: null | string | ReactNode
}

interface AdvancedSelectFieldProps<IsMulti extends boolean = boolean> {
  value?: null | (IsMulti extends true ? SelectOption[] : string)
  isMulti?: IsMulti
  name: string
  options: SelectOption[]
  asyncSearchFunction: null | ((inputValue: string) => Promise<SelectOption[]>)
  placeholder: string
  className?: null | string
  inputValue?: null | string
  menuIsOpen?: null | boolean
  onChange?: null | ReactSelectProps["onChange"]
  saveOnChange?: null | ((name: string, value: string) => void)
  onInputChange?: null | ReactSelectProps["onInputChange"]
  icon?: null | string
  formatOptionLabel?: null | ((option: SelectOption) => string | ReactNode)
  noOptionsMessage?: null | string
  width?: null | string | NonNegativeInteger<number>
  menuWidth?: null | string | NonNegativeInteger<number>
  menuHeight?: null | string | NonNegativeInteger<number>
  borderRadius?: null | string | NonNegativeInteger<number>
  borderRadiusOpen?: null | string | NonNegativeInteger<number>
  components?: null | ReactSelectProps["components"]
  forwardRef?: null | Ref<SelectInstance>
  disabled?: boolean
  fadeWhenDisabled?: boolean
  overflowHidden?: boolean
  alignCenter?: boolean
  alignRight?: boolean
  hideCaret?: boolean
  autoSelectSingleExactMatch?: boolean
}

const AdvancedSelectField = ({
  name,
  options,
  asyncSearchFunction,
  placeholder,
  className = null,
  value = null,
  inputValue = null,
  menuIsOpen = null,
  // NOTES: onChange vs. saveOnChange
  // onChange is a basic change handler that simply executes the provided function
  // when select value changes occur. Handler: ({ value }) => ...process value...
  // saveOnChange is important for compatibility with our forms and exercises.
  // In addition to executing the handler, changes also update the form field value.
  // In general, use onChange if you want to do something simple that doesn't involve
  // a form; otherwise use saveOnChange.
  onChange = null,
  saveOnChange = null,
  onInputChange = null,
  // onInputChange handler is executed on every keypress during select search
  icon = null,
  formatOptionLabel = null,
  noOptionsMessage = null,
  width = null,
  components = null,
  forwardRef = null,
  menuWidth: _menuWidth = null,
  menuHeight: _menuHeight = null,
  borderRadius: _borderRadius = null,
  borderRadiusOpen: _borderRadiusOpen = null,
  fadeWhenDisabled: _fadeWhenDisabled = true,
  isMulti = false,
  disabled = false,
  overflowHidden = false,
  alignCenter = false,
  alignRight = false,
  hideCaret = false,
  autoSelectSingleExactMatch = true,
}: AdvancedSelectFieldProps) => {
  const [{ value: fieldValue }, _, { setValue }] = useField(name)

  const handleChange = useCallback(
    (option: unknown, actionMeta: ActionMeta<unknown>) => {
      const { value } = option as SelectOption
      setValue(value)
      if (saveOnChange && onChange) {
        throw new Error("AdvancedSelectField: Only specify one of onChange and saveOnChange props at once.")
      } else if (saveOnChange) {
        saveOnChange(name, value)
      } else if (onChange) {
        onChange(option, actionMeta)
      }
    },
    [name, onChange, saveOnChange, setValue]
  )

  const handleInputChange = useCallback(
    (inputFilterValue: string, actionMeta: InputActionMeta) => {
      if (autoSelectSingleExactMatch && !isMulti && inputFilterValue?.length) {
        // autoSelectSingleExactMatch setting doesn't work with multi-selects
        const [singleExactMatch, ...otherMatches] = options.filter(
          ({ label }) =>
            typeof label === "string" && label.toLowerCase().trim() === inputFilterValue.toLowerCase().trim()
        )
        if (singleExactMatch && !otherMatches?.length) {
          handleChange(singleExactMatch, actionMeta as unknown as ActionMeta<unknown>)
        }
      }
    },
    [autoSelectSingleExactMatch, isMulti, options, handleChange]
  )

  // Copied from: https://github.com/JedWatson/react-select/issues/614#issuecomment-679056254
  // eslint-disable-next-line react-hooks/exhaustive-deps -- useCallback is unable to determine dependencies since debounce is not an inline function
  const asyncLoadOptions = useCallback(
    debounce((searchTerm, callback) => {
      asyncSearchFunction?.(searchTerm).then((options) => callback(options))
    }, 500),
    [asyncSearchFunction]
  )

  const selected = options.find((option) => option.value === fieldValue)

  if (options.some((option) => typeof option.label !== "string")) {
    throw new Error(
      "AdvancedSelectField.js: All option labels must be strings, otherwise search functionality will not work."
    )
  }

  // Set a default option label formatter function; callers may pass a custom
  // formatOptionLabel function in as a prop which will override this default.
  const internalFormatOptionLabel =
    formatOptionLabel ??
    (({ label, icon, iconClassName }) => {
      const Icon = icon ? getIconOrError(icon) : () => null
      return (
        <>
          {label}
          <Icon className={cn("ml-xxs", iconClassName)} />
        </>
      )
    })

  // We override React Select's IconControl component to use our own icon styling:
  const IconControl = ({
    icon = null,
    children,
    ...props
  }: {
    icon?: null | string
    children: ReactNode
    props: ControlProps<SelectOption>
  }) => (
    // @ts-ignore TODO@evnp debug this react-select Control props typing issue
    <reactSelectSubComponents.Control {...props}>
      {!!icon && <span style={{ transform: "translateX(10px)" }}>{icon}</span>}
      {children}
    </reactSelectSubComponents.Control>
  )

  // We override React Select's SingleValue component to provide formattedSelectedLabel
  // functionality; option data can define custom content/styling for the menu button
  // when the option is selected:
  const SingleValue = (props: SingleValueProps<SelectOption>) => {
    const option = props.data
    return (
      <reactSelectSubComponents.SingleValue {...props}>
        {option.formattedSelectedLabel ?? internalFormatOptionLabel(option)}
      </reactSelectSubComponents.SingleValue>
    )
  }

  const SelectComponent = asyncSearchFunction ? AsyncSelect : Select
  const selectOptionsParams = asyncSearchFunction
    ? { defaultOptions: options, loadOptions: asyncLoadOptions }
    : { options }

  return (
    <SelectComponent
      className={cn(className, {
        "advanced-select--overflow-hidden": overflowHidden,
        "advanced-select--align-center": alignCenter,
        "advanced-select--align-right": alignRight,
        "advanced-select--hide-caret": hideCaret,
      })}
      classNamePrefix="advanced-select"
      name={name}
      placeholder={placeholder}
      onChange={handleChange}
      onInputChange={onInputChange ?? handleInputChange}
      {...selectOptionsParams}
      // These props cannot be passed as null; instead omit them entirely if null:
      {...(value != null ? { value } : {})}
      {...(inputValue != null ? { inputValue } : {})}
      {...(menuIsOpen != null ? { menuIsOpen } : {})}
      {...(noOptionsMessage != null ? { noOptionsMessage } : {})}
      // Key prop isnecessary to ensure select always updates when form values
      // change and for autoSelectSingleExactMatch behavior to work properly:
      key={selected?.value}
      ref={forwardRef as unknown as undefined} // TODO@evnp is there a clearer way to make this typing work?
      defaultValue={selected}
      isMulti={isMulti as false} // TODO@evnp is there a clearer way to make this typing work?
      isDisabled={!!disabled}
      // @ts-ignore TODO@evnp debug this react-select width prop typing issue
      width={width}
      components={
        {
          SingleValue,
          IndicatorSeparator: () => null,
          // @ts-ignore TODO@evnp debug this IconControl props typing issue
          ...(icon ? { Control: (props) => <IconControl icon={icon} {...props} /> } : {}),
          ...(components ?? {}),
        } as ReactSelectProps["components"]
      }
      // E2E Test-related Configuration:
      // Add a wrapper to formatOptionLabel to add data-testvalue attributes to
      // select options so they can be reliably targeted by value within e2e tests:
      formatOptionLabel={
        ((option: SelectOption) => (
          <span data-testvalue={option.value}>{internalFormatOptionLabel(option)}</span>
        )) as ReactSelectProps["formatOptionLabel"]
      }
      openMenuOnFocus // This leads to better keyboard-nav and makes testing easier.
    />
  )
}

export default styled(AdvancedSelectField)`
  .advanced-select__control {
    border: 0;
    ${({ borderRadius = null }) =>
      formatLength("border-radius", borderRadius, {
        fallbackValueIfInvalidLength: borderRadius ?? "var(--border-radius)",
      })}
    background-color: var(--fg);
    box-shadow: var(--blur-4);
    cursor: pointer;
    height: 44px;

    &:focus-visible,
    &:hover {
      box-shadow: var(--lift-4);
    }
  }

  .advanced-select__placeholder {
    line-height: 0;
  }
  .advanced-select__single-value,
  .advanced-select__input-container {
    color: var(--subtitle);
    line-height: 1rem;
    padding: 0;
    overflow: visible;
  }

  .advanced-select__value-container {
    padding: 12px 0 12px 12px;
  }

  .advanced-select__control {
    width: max-content;
    ${({ width = null }) => formatLength("width", width)}
  }

  .advanced-select__menu {
    margin: 0;
    z-index: var(--z-menu);
    width: max-content;
    ${({ width = null }) => formatLength("min-width", width)}
    ${({ menuWidth = null }) => formatLength("width", menuWidth)}
    ${({ menuHeight = null }) => formatLength("height", menuHeight)}

    // Add padding to bottom of select menu so that if it would hit the bottom of page
    // or the bottom of a modal, a gap is shown to avoid the menu looking cut-off:
    padding-bottom: var(--spacing-4);
    background-color: transparent; // move these styles to the inner menu-list element below
    box-shadow: none; // so they don't wrap around the padding added above
  }
  .advanced-select__menu-list {
    ${({ menuHeight = null }) => formatLength("height", menuHeight)}

    // These styles are taken from react-select's default styling for the menu element:
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  }

  .advanced-select__option {
    cursor: pointer;
    max-width: 100%;
  }

  .advanced-select__control--menu-is-open {
    ${({ borderRadiusOpen = null }) =>
      formatLength("border-radius", borderRadiusOpen, {
        fallbackValueIfInvalidLength: borderRadiusOpen ?? "var(--border-radius) var(--border-radius) 0 0",
      })}
    & + .advanced-select__menu .advanced-select__menu-list {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
  }

  &.advanced-select--is-disabled {
    ${({ fadeWhenDisabled = true }) => (fadeWhenDisabled ? "opacity: 0.5;" : "")};
  }

  &.advanced-select--overflow-hidden {
    .advanced-select__menu {
      max-width: 100%;
    }
    .advanced-select__option {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.advanced-select--align-center {
    .advanced-select__control {
      text-align: center;
    }
  }

  &.advanced-select--align-right {
    .advanced-select__control {
      text-align: right;
    }
  }

  &.advanced-select--hide-caret {
    .advanced-select__control {
      padding-right: 1rem;
    }
    .advanced-select__indicators {
      display: none;
    }
  }
`

export type { SelectOption, AdvancedSelectFieldProps }
