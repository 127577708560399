import BoHOXPlaybook from "./BoHOXPlaybook"
import CareerNewsHeadlines from "./CareerNewsHeadlines"
import CivilConversationsFacilitationGuide from "./CivilConversationsFacilitationGuide"
import HorizontalTeammojis from "./HorizontalTeammojis"
import ImageGrid from "./ImageGrid"
import OrigamiExercises from "./OrigamiExercises"
import PaperAirplanes from "./PaperAirplanes"
import PeerTemplate from "./PeerTemplate"
import RoadTemplate from "./RoadTemplate"
import SoundBoard from "./SoundBoard"
import TeamNormsHeadlinesWithBackground from "./TeamNormsHeadlinesWithBackground"
import VerticalTeammojis from "./VerticalTeammojis"
import VirtualBackgrounds from "./VirtualBackgrounds"
import ZoomBackground from "./ZoomBackground"

const BonusComponents = {
  BoHOXPlaybook,
  CareerNewsHeadlines,
  CivilConversationsFacilitationGuide,
  HorizontalTeammojis,
  ImageGrid,
  OrigamiExercises,
  PaperAirplanes,
  PeerTemplate,
  RoadTemplate,
  SoundBoard,
  VerticalTeammojis,
  VirtualBackgrounds,
  ZoomBackground,
  TeamNormsHeadlinesWithBackground,
}

export {
  BoHOXPlaybook,
  CareerNewsHeadlines,
  CivilConversationsFacilitationGuide,
  HorizontalTeammojis,
  ImageGrid,
  OrigamiExercises,
  PaperAirplanes,
  PeerTemplate,
  RoadTemplate,
  SoundBoard,
  VerticalTeammojis,
  ZoomBackground,
  TeamNormsHeadlinesWithBackground,
}

export default BonusComponents
