import * as Sentry from "@sentry/browser"
import { useQueryClient } from "@tanstack/react-query"
import cn from "classnames"
import { Formik, Form } from "formik"
import { useState } from "react"
import { Navigate } from "react-router"
import { useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import { Accordion, SearchInput, SearchResults, SearchHighlight } from "components"
import TeamModal from "domains/Admin/TeamModal"
import { Yup, handleErrors, FormMessage } from "forms"
import { TextField, SelectField } from "forms/fields"
import { demoTeamSchemas } from "forms/yup"
import { PenToSquareIcon, TrashIcon } from "icons/FontAwesomeIcons"
import { useDemoAccount } from "resources/billing"
import {
  prefetchDemoTeams,
  useDemoTeams,
  useCreateDemoTeam,
  useSelectDemoTeam,
  useDeleteDemoTeam,
  DemoTeamTypeLabel,
  useCurrentDemoTeam,
} from "resources/teams"
import { useUser } from "resources/users"
import { View, Loading, HorizontalRule, SubmitButton } from "ui"
import { useQueryParams, useEffectAfterChange, useEffectAfterFirstRender } from "ui/hooks"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import { formatTimestampAsDate } from "utils/date"

const demoTeamSchema = Yup.object().shape(demoTeamSchemas)

const DemoHome = styled(function DemoHome({ className }) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { edit } = useQueryParams()
  const createDemoTeamNav = () => navigate("/kit")
  const { mutateAsync: createDemoTeam } = useCreateDemoTeam({
    onSuccess: createDemoTeamNav,
  })
  const { [FLAGS.DEMO_MODE]: demo_mode, isFetching } = useFeatures()
  const { data: user } = useUser({ userId: "me" })
  const [query, setQuery] = useState([])

  // Ensure demo team list is pre-fetched so it becomes instantly
  // visible (as soon as possible) after list accordion is expanded:
  useEffectAfterFirstRender(() => {
    prefetchDemoTeams({ queryClient })
  })

  if (!user || isFetching) {
    return <Loading />
  }

  // Ensure only RT staff with demo mode active may access demo page:
  if (!user.is_staff || !user.is_demo_mode_active || !demo_mode) {
    return <Navigate to="/" replace />
  }

  const submit = handleErrors(createDemoTeam, (_data, _values, _formik, err) => {
    Sentry.captureException(err)
    return {
      message: "There was an error creating your demo team, please try again.",
    }
  })

  return (
    <View className={className}>
      <div className="main-container">
        <h1 className="text-gray-9">Demo</h1>
        <HorizontalRule />
        <Formik initialValues={{ org_name: "" }} validationSchema={demoTeamSchema} onSubmit={submit}>
          <Form name="demo-form" autoComplete="off">
            <View $flexDirectionMobile="column" className="mb-medium">
              <TextField name="org_name" placeholder="Enter org name" />
              <SelectField
                name="demo_type"
                size="medium"
                className="ml-medium-mobile-never mt-medium-mobile-only fit-content"
              >
                {Object.entries(DemoTeamTypeLabel).map(([value, label]) => (
                  <option value={value} key={value}>
                    {label} Demo
                  </option>
                ))}
              </SelectField>
            </View>
            <FormMessage />
            <SubmitButton>Create demo team</SubmitButton>
          </Form>
        </Formik>
        <HorizontalRule />
        <SearchInput className="mt-xl" query={query} setQuery={setQuery} placeholder="Search for a demo team" /> <br />
        <Accordion noIndent key={query} isOpen={!!query.length || !!edit} title={<h3>Demo Teams</h3>}>
          <DemoList query={query} />
        </Accordion>
      </div>
    </View>
  )
})`
  .main-container,
  input {
    width: 100%;
  }
  form {
    padding: 2rem 0 1rem 0;
  }
  .fa-xmark {
    cursor: pointer;
    transition: color 0.1s ease;
    &:hover {
      color: var(--gray9);
    }
  }
`

const DemoList = styled(function DemoList({ className, query }) {
  const navigate = useNavigate()
  const { edit } = useQueryParams()
  const [showEditTeamModal, setShowEditTeamModal] = useState(null)
  const { mutateAsync: selectDemoTeam } = useSelectDemoTeam({
    onSuccess: () => navigate("/kit"),
  })
  const { mutateAsync: deleteDemoTeam } = useDeleteDemoTeam({
    onSuccess: () => navigate("/demo"),
  })
  const { data: demoTeams } = useDemoTeams()
  const { data: user } = useUser({ userId: "me" })
  const { data: currentTeam } = useCurrentDemoTeam()
  const { data: demoAccount, isFetching } = useDemoAccount()

  useEffectAfterChange(() => {
    if (edit) {
      setShowEditTeamModal(demoTeams?.find((team) => team.id === parseInt(edit)) ?? null)
    }
  }, [demoTeams, edit])

  if ((!demoAccount && isFetching) || !user || !demoTeams) {
    return <Loading />
  }

  const currTeamId = currentTeam?.id

  function confirmDeleteDemoTeam(id) {
    if (
      window.confirm(
        "Are you sure you want to delete this demo team? This operation cannot be undone. Click OK to delete."
      )
    ) {
      deleteDemoTeam(id)
    }
  }

  return (
    <div className={className}>
      <ul>
        <SearchResults
          query={query}
          items={demoTeams}
          str={(team) => team.name}
          empty={<li>No demo teams found.</li>}
          result={(team) => (
            <li
              key={team.id}
              className={cn({ selected: team.id === currTeamId })}
              onClick={() => team.id !== currTeamId && selectDemoTeam(team.id)}
            >
              <div>
                <SearchHighlight
                  text={team.name}
                  matches={query}
                  highlight={(text, idx) => (
                    <b key={idx} className="text-gray-9">
                      {text}
                    </b>
                  )}
                />
              </div>
              <div></div>
              <div>{DemoTeamTypeLabel[team.demo_type] ?? DemoTeamTypeLabel.STANDARD} Demo</div>
              <div></div>
              <div>Created on {formatTimestampAsDate(team.created_at)}</div>
              <div className="demo-controls" onClick={(ev) => ev.stopPropagation()}>
                <PenToSquareIcon
                  className="m-xxs ml-small text-rising-blue"
                  onClick={() => setShowEditTeamModal(team)}
                />
                <TrashIcon className="m-xxs ml-small text-danger" onClick={() => confirmDeleteDemoTeam(team.id)} />
              </div>
            </li>
          )}
        />
      </ul>
      <TeamModal
        isAdmin
        hideTeamLeadField
        account={demoAccount}
        user={user}
        isOpen={!!showEditTeamModal}
        team={showEditTeamModal}
        onRequestClose={() => {
          setShowEditTeamModal(null)
          // remove 'edit' url query param on modal close:
          window.history.replaceState(null, "", window.location.toString().replace(window.location.search, ""))
        }}
      />
    </div>
  )
})`
  ul,
  h2 {
    padding: 1rem 0;
  }
  li {
    list-style: none;
    cursor: pointer;
    display: flex;
  }
  li > * {
    transition: opacity 0.2s ease;
  }
  li > :nth-child(even):not(:last-child) {
    width: 1rem;
    margin: 9px;
    opacity: 0.5;
    border-bottom: 2px dotted var(--rising-orange);
  }
  li > :nth-child(2) {
    flex-grow: 1;
  }
  li:hover,
  li.selected {
    font-weight: bold;
    letter-spacing: -0.023rem;
    color: var(--rising-orange);
  }
  :hover li.selected:not(:hover) > :not(:last-child) {
    opacity: 0.6;
  }
  .demo-controls svg {
    opacity: 0.4;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 1;
    }
  }
`

export default DemoHome
