import { styled } from "styled-components"

import { DownloadIcon } from "icons/FontAwesomeIcons"
import { View, Button } from "ui"

const CivilConversationsFacilitationGuide = styled(function CivilConversationsFacilitationGuide({ kit, className }) {
  return (
    <View $flexDirection="row" $flexDirectionMobile="column" className={className}>
      <img
        src={kit.bonus_page.bonus_page_image_url}
        alt="Screenshot of the facilitation guide on a tablet"
        className="mr-medium"
      />
      <View $flexDirection="column">
        <h2 className="text-semi-bold mb-medium">Facilitation Guide</h2>
        {!!kit.bonus_page.bonus_desc && (
          <div className="mb-large">
            {kit.bonus_page.bonus_desc.map((description, index) => (
              <p className="mb-xs" key={index}>
                {description.paragraph_text}
              </p>
            ))}
          </div>
        )}
        <View>
          <a href={kit.assets.facilitation_guide_download_pdf_link} target="_blank" rel="noopener noreferrer">
            <Button className="tertiary mb-xl">
              <DownloadIcon />
              Download the PDF
            </Button>
          </a>
        </View>
      </View>
    </View>
  )
})`
  img {
    max-width: 200px;
  }
`

export default CivilConversationsFacilitationGuide
