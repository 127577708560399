import cn from "classnames"
import { Fragment, type ReactNode } from "react"
import { styled } from "styled-components"
import type { RTTheme } from "types"

import { useCurrentTheme } from "./ThemeUpdateContext"
import Tooltip from "./Tooltip"
import View from "./View"

interface RowItem {
  ariaLabel?: string
  columns: { component: ReactNode; tooltip?: ReactNode; className?: string }[]
}

const RTGridTable = styled(function RTBar({
  headers,
  rowItems,
  numColumns: _numColumns,
  gridTemplateColumns: _gridTemplateColumns,
  gridTemplateColumnsMobile: _gridTemplateColumnsMobile,
  className,
}: {
  headers?: string[]
  rowItems: RowItem[]
  numColumns: number
  gridTemplateColumns: string
  gridTemplateColumnsMobile: string
  className: string
}) {
  // @ts-ignore
  const theme: RTTheme = useCurrentTheme()
  const headersClassName = theme.isWidescreen ? "text-gray-7 text-medium text-normal-mobile-only" : "text-semi-bold"

  return (
    <div className={className}>
      {headers?.map((header, index) => (
        <div key={index} aria-hidden className={cn(headersClassName, { "label-column": index === 0 })}>
          {header}
        </div>
      ))}
      {rowItems.map((rowItem, rowIndex) => (
        <Fragment key={rowIndex}>
          {rowItem.columns.map((column, columnIndex) => {
            // Need to set to undefined for typescript
            // eslint-disable-next-line no-undefined
            const ariaLabel = columnIndex === 0 ? rowItem.ariaLabel : undefined
            const component = (
              <View
                key={columnIndex}
                aria-label={ariaLabel}
                aria-hidden={!ariaLabel}
                className={cn("align-self-center", column.className, { "label-column": columnIndex === 0 })}
              >
                {column.component}
              </View>
            )
            return column.tooltip ? (
              <Tooltip top float wrapInView={false} maxWidth={350} title={column.tooltip}>
                {component}
              </Tooltip>
            ) : (
              component
            )
          })}
        </Fragment>
      ))}
    </div>
  )
})`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  gap: var(--spacing-2) var(--spacing-2);

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: ${({ gridTemplateColumnsMobile }) => gridTemplateColumnsMobile};

    .label-column {
      grid-column-start: span ${({ numColumns }) => numColumns - 1};
      justify-self: start;
    }
  }
`

export default RTGridTable
