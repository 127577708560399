import { useQueryClient } from "@tanstack/react-query"
import cn from "classnames"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useSwipeable } from "react-swipeable"
import { styled } from "styled-components"

import KitTypePill from "domains/LeaderKit/KitsTableofContents/components/KitTypePill"
import { getRefetchKitHomeKitsFunction } from "domains/LeaderKit/KitsTableofContents/utils"
import ScheduleForm from "domains/LeaderKit/ScheduleForm"
import { formatKitSessionTime } from "domains/LeaderKit/utils"
import getIconOrError from "icons"
import {
  CircleIcon,
  CircleChevronLeftIcon,
  CircleChevronRightIcon,
  ClockIcon,
  CircleInfoIcon,
} from "icons/FontAwesomeIcons"
import { useUserAccount } from "resources/billing"
import { View } from "ui"
import { useWindowSize } from "ui/hooks"
import { useModal } from "ui/ModalContext"
import { plural } from "utils/string"

const ScheduleNext = styled(function ScheduleNext({ scheduleNextKits, kitInstanceMap, selectedTeam, className }) {
  const queryClient = useQueryClient()
  const [activeKitIndex, setActiveKitIndex] = useState(0)
  const { isMobileOrSmaller, isMobileLandscapeOrLarger, isTabletOrLarger } = useWindowSize()
  const { data: userAccount } = useUserAccount(selectedTeam?.account_id)
  const maxSessionParticipants = userAccount?.max_session_participants
  const { setModal } = useModal()

  const activeKit = scheduleNextKits[activeKitIndex]
  const activeKitInstance = kitInstanceMap[activeKit?.slug]
  const hasPrevious = activeKitIndex > 0
  const hasNext = activeKitIndex < scheduleNextKits?.length - 1
  const linkUrl = activeKitInstance ? activeKitInstance.home_url : `/kit/${activeKit?.slug}?team_id=${selectedTeam.id}`
  const Icon = activeKit?.icon && getIconOrError(activeKit.icon)
  const iconCustomLogo = activeKit?.schedule_next_icon_custom_logo

  const showModal = () =>
    setModal({
      title: "Scheduling tips",
      content: <SchedulingTips maxSessionParticipants={maxSessionParticipants} />,
      size: "medium",
      styles: { content: { overflow: "auto" } },
    })

  const viewPrevious = () => {
    if (hasPrevious) {
      setActiveKitIndex(activeKitIndex - 1)
    }
  }
  const viewNext = () => {
    if (hasNext) {
      setActiveKitIndex(activeKitIndex + 1)
    }
  }
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => viewPrevious(),
    onSwipedLeft: () => viewNext(),
    preventScrollOnSwipe: true,
  })

  // Handle case where active index no longer exists if scheduleNextKits shrinks
  if (scheduleNextKits.length > 0 && activeKitIndex > scheduleNextKits.length - 1) {
    setActiveKitIndex(Math.max(scheduleNextKits.length - 1, 0))
    return null
  }
  if (!scheduleNextKits.length) {
    return null
  }

  return (
    <div className={className}>
      <View $justifyContent="space-between" $alignItems="center" className="mb-xs">
        <h3 className="text-semi-bold">
          Schedule {plural(scheduleNextKits.length, "this", { pluralForm: "these", showCount: false })} next
        </h3>
        <p className="text-gray-7 text-semi-bold cursor-pointer" onClick={showModal}>
          {!!isMobileLandscapeOrLarger && "Scheduling tips "}
          <CircleInfoIcon className="ml-xxs" />
        </p>
      </View>
      <p className="mb-medium">
        We suggest booking several sessions up front to block time on people’s calendars and set a nice cadence.
      </p>
      <div
        className="active-kit-detail border border-gray-5 border-radius-small lift-2 p-large space-y-medium"
        {...swipeHandlers}
      >
        <View
          $justifyContent="space-between"
          $flexDirectionMobile="column"
          $alignItems="center"
          $alignItemsMobile="center"
        >
          <Link to={linkUrl} className="text-gray-9 no-underline">
            <View
              className={cn("fit-content", { "mb-xs": isMobileOrSmaller })}
              $alignItems="center"
              $flexDirectionMobile="column"
            >
              {iconCustomLogo ? (
                <img
                  src={iconCustomLogo}
                  alt="icon-custom-logo"
                  className={cn({
                    "mr-medium": isMobileLandscapeOrLarger,
                    "mb-xs": isMobileOrSmaller,
                  })}
                  height="48px"
                />
              ) : (
                <Icon
                  className={cn("title-icon gradient-icon", {
                    "mr-medium": isMobileLandscapeOrLarger,
                    "mb-xs": isMobileOrSmaller,
                  })}
                />
              )}
              <h2 className={cn({ "text-center": isMobileOrSmaller })}>{activeKit.title}</h2>
            </View>
          </Link>
          <View
            $flexDirection={isMobileOrSmaller ? "column" : "row"}
            $alignItems="center"
            $justifyContent="right"
            className="fit-content"
          >
            {/* TODO follow up with Tanya to make this layout work on mobile-landscape */}
            {(!!isMobileOrSmaller || !!isTabletOrLarger) && (
              <KitTypePill selectedKitInfo={activeKit} className={cn("mr-small", { "mb-xs": isMobileOrSmaller })} />
            )}
            <p className="text-gray-8 text-nowrap">
              <ClockIcon className="text-gray-8 mr-xxs" />
              {formatKitSessionTime(activeKit?.session_time_minutes)}
            </p>
          </View>
        </View>
        {!activeKit.learn_description && !activeKit.do_description && <p>{activeKit.description}</p>}
        {!!activeKit.learn_description && (
          <p>
            <span className="text-bold">What you'll learn:</span> {activeKit.learn_description}
          </p>
        )}
        {!!activeKit.do_description && (
          <p>
            <span className="text-bold">What you'll do:</span> {activeKit.do_description}
          </p>
        )}
        {!!isMobileLandscapeOrLarger && (
          <p className="bg-highlight py-xs px-medium">
            To schedule, pick a date and time that works for your team. When you create the calendar invite, add your
            team and a video link or conference room if needed.
          </p>
        )}
        <View
          $alignItems="center"
          $alignItemsMobile="flex-start"
          $justifyContent="space-between"
          $flexDirectionMobile="column-reverse"
          className="mt-medium"
        >
          <ScheduleForm
            kitInstance={activeKitInstance}
            kitInfo={activeKit}
            onAddToCalendar={getRefetchKitHomeKitsFunction(selectedTeam.id, queryClient)}
            team={selectedTeam}
            className="fit-content"
          />
          <Link to={linkUrl} className="text-semi-bold">
            Learn more
          </Link>
        </View>
        {!!hasPrevious && !!isMobileLandscapeOrLarger && (
          <CircleChevronLeftIcon
            className="change-kit change-kit-previous text-large text-rising-blue cursor-pointer interactive-shadow"
            onClick={viewPrevious}
          />
        )}
        {!!hasNext && !!isMobileLandscapeOrLarger && (
          <CircleChevronRightIcon
            className="change-kit change-kit-next text-large text-rising-blue cursor-pointer interactive-shadow"
            onClick={viewNext}
          />
        )}
      </div>
      {scheduleNextKits.length > 1 && (
        <div className="mt-medium">
          <View className="space-x-medium fit-content mx-auto">
            {scheduleNextKits.map((_, idx) => (
              <CircleIcon
                key={`progress-circle-${idx + 1}`}
                onClick={() => setActiveKitIndex(idx)}
                className={cn("cursor-pointer", {
                  "text-gray-5": idx !== activeKitIndex,
                  "text-orange-2": idx === activeKitIndex,
                })}
              />
            ))}
          </View>
        </div>
      )}
    </div>
  )
})`
  .active-kit-detail {
    position: relative;

    .change-kit {
      position: absolute;
      top: 155px;
      background-color: var(--white);
      border-radius: 50%;
    }

    .change-kit-previous {
      left: -16px;
    }

    .change-kit-next {
      right: -16px;
    }
  }
`

const SchedulingTips = ({ maxSessionParticipants }) => (
  <div className="text-normal space-y-small">
    <p>
      Sessions can be done remotely or in-person, as long as everyone has a computer or mobile device and internet
      access.
    </p>
    <p>
      <span className="text-bold">Session length:</span> Our calendar invites book what should be the maximum amount of
      time for your team to complete each session. On average, most teams finish in less time, but larger and more
      extroverted teams can take longer.
    </p>
    <p>
      <span className="text-bold">Team size:</span> Rising Team sessions are highly interactive with frequent
      opportunities for sharing. To ensure everyone has time to share we limit the team size to {maxSessionParticipants}{" "}
      total participants.
    </p>
    <p>
      <span className="text-bold">Timing:</span> We recommend completing a session every 4-6 weeks to build consistent
      growth and connection with your team.
    </p>
    <p>
      <span className="text-bold">Recommended order:</span> Available kits are presented in the order recommended for
      most teams, but feel free to adjust the order if there is a topic that is especially relevant to your team’s
      immediate needs.
    </p>
  </div>
)

export default ScheduleNext
