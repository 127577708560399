import { useQueryClient } from "@tanstack/react-query"
import cn from "classnames"
import { Fragment, useState } from "react"
import { isSafari, isIOS } from "react-device-detect"
import { useNavigate } from "react-router"
import { styled } from "styled-components"
import useTimeout from "use-timeout"

import { authCacheKey } from "./resource"

import { isUsingSameAuthDomain, initializeFirebaseUI } from "domains/Authentication/firebase"
import { Loading } from "ui"
import { useEffectAfterFirstRender, useQueryParams } from "ui/hooks"

const FIREBASEUI_CONTAINER_ID = "firebaseui-auth-container"

function useFirebaseAuthSignInRedirectPending() {
  // Default to isSignInRedirectPending=true. This allows us to avoid a flash
  // of login UI after Firebase Auth UX redirects back to our app. If a redirect
  // sign-in is not pending, isSignInRedirectPending=false will immediately be set
  // by FirebaseUI component's onNoRedirectPending, and full login UI will be shown:
  const [isSignInRedirectPending, setShowSignInRedirectPending] = useState(true)
  const clearSignInRedirectPending = () => setShowSignInRedirectPending(false)
  // If Firebase Auth hasn't loaded pending-redirect user within 5 seconds,
  // show full login UI regardless of current state (avoid infinite-spinner):
  useTimeout(clearSignInRedirectPending, 5000)
  return [isSignInRedirectPending, clearSignInRedirectPending]
}

function getFirebaseUIContainerElement() {
  return document.getElementById(FIREBASEUI_CONTAINER_ID) ?? null
}

const FirebaseUI = ({
  className,
  ssoProviders = [],
  redirectUrl = null,
  ssoRedirectLogin = false,
  hidden = false,
  containerIdSuffix = null,
}) => {
  const { next } = useQueryParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const containerId = containerIdSuffix ? `${FIREBASEUI_CONTAINER_ID}-${containerIdSuffix}` : FIREBASEUI_CONTAINER_ID

  useEffectAfterFirstRender(() => {
    // No need to await here even though initializeFirebaseUI is an async function:
    initializeFirebaseUI(containerId, {
      ssoProviders,
      ssoRedirectLogin,
      onSignIn: () => {
        queryClient.removeQueries({ queryKey: authCacheKey, exact: true })
        navigate(redirectUrl || next || "/")
      },
    })
  })

  return (
    <Fragment>
      {!hidden && !isUsingSameAuthDomain() && !!(isSafari || isIOS) && (
        <p className="text-danger">
          We are currently experiencing issues in Safari and on iOS devices. Please use another browser or device for a
          smoother experience.
        </p>
      )}
      {!!hidden && (
        <p className="mb-xl">
          Logging you in...
          <Loading className="mb-small" />
          Please wait. This can take up to one minute.
        </p>
      )}
      <div className={cn(className, { hidden })}>
        <div id={containerId}></div>
      </div>
    </Fragment>
  )
}

export default styled(FirebaseUI)`
  &.hidden {
    display: none;
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #${FIREBASEUI_CONTAINER_ID} {
    // Prevent change of content height when FirebaseUI initializes,
    // and also fade in the UI smoothly:
    &:empty {
      min-height: 128px;
      opacity: 0;
    }
    &:not(:empty) {
      opacity: 1;
    }
    transition: opacity 0.2s ease;

    // fix button centering:
    div {
      padding: 0;
    }

    // eliminate extra space on right side of buttons:
    button {
      width: fit-content;
    }

    // fix terms & conditions layout:
    p {
      padding: 0 24px;
    }
  }
`

export { getFirebaseUIContainerElement, useFirebaseAuthSignInRedirectPending }
