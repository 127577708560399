import { useParams } from "react-router"

import { GRATITUDE_PROMO_CODE } from "domains/Promo/constants"
import { useQueryParams } from "ui/hooks"

const usePromoCode = () => {
  const { promoCode: promoCodeParam } = useParams()
  const { gratitude_promo: gratitudePromo, promo: promoCodeQueryParam } = useQueryParams()

  if (gratitudePromo) {
    return GRATITUDE_PROMO_CODE
  }

  return promoCodeParam ?? promoCodeQueryParam
}

// disabled since we'll add more util exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { usePromoCode }
