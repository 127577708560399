import { createContext, useContext } from "react"
import { useLocation } from "react-router"

import { useEffectAfterChange, useQueryParams } from "./hooks"

import { useKitInstance } from "resources/monthly_kit"
import { sortUserTeams, useTeamSelectorTeams, useTeam } from "resources/teams"
import { useUser } from "resources/users"

const SelectedTeamContext = createContext({
  selectedTeamId: null,
  setSelectedTeamId: () => {},
})

const useSelectedTeamId = () => useContext(SelectedTeamContext) || {}

const useSelectedTeam = () => {
  const { selectedTeamId } = useSelectedTeamId()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  return { selectedTeam }
}

const useSelectedTeamIdWithTeamIdQueryParam = ({
  allowJumboTeams = true,
  redirectIfNotSelectableTeam = false,
} = {}) => {
  const location = useLocation()
  const { data: user } = useUser({ userId: "me" })
  const { selectedTeamId, setSelectedTeamId } = useSelectedTeamId()
  const { team_id: queryParamTeamIdStr } = useQueryParams()
  const queryParamTeamId = parseInt(queryParamTeamIdStr)
  const queryParamTeamIdAlreadySelected = selectedTeamId === queryParamTeamId

  const isScheduleModeActive = location?.state?.fetchScheduleModeStatus
  const { data: scheduleModeTeam } = useTeam({ teamId: queryParamTeamId, enabled: isScheduleModeActive })

  const { data: regularModeTeams } = useTeamSelectorTeams({
    enabled: !isScheduleModeActive && !queryParamTeamIdAlreadySelected,
  })

  const nonJumboTeams = regularModeTeams?.filter(({ jumbo }) => !jumbo) ?? []
  const scheduleModeTeams = scheduleModeTeam ? [scheduleModeTeam] : []
  const selectableTeams = isScheduleModeActive ? scheduleModeTeams : !allowJumboTeams ? nonJumboTeams : regularModeTeams

  const queryParamTeam = (selectableTeams ?? []).find(({ id }) => id === queryParamTeamId)

  useEffectAfterChange(() => {
    if (!queryParamTeamIdAlreadySelected && queryParamTeam) {
      setSelectedTeamId(queryParamTeam.id)
    }
  }, [queryParamTeamIdAlreadySelected, setSelectedTeamId, queryParamTeam])

  const redirectTeamId = getRedirectTeamId({
    user,
    selectableTeams,
    selectedTeamId,
    queryParamTeamIdStr,
    queryParamTeam,
  })

  let needsRedirect = !queryParamTeamIdAlreadySelected
  if (redirectIfNotSelectableTeam && !queryParamTeam) {
    needsRedirect = true
  }

  return { selectedTeamId, redirectTeamId, needsRedirect }
}

const getRedirectTeamId = ({ user, selectableTeams, selectedTeamId, queryParamTeamIdStr, queryParamTeam }) => {
  const selectableTeamIds = new Set((selectableTeams ?? []).map(({ id }) => id))

  // if there is a selected team but no team_id in the query params, redirect to include the team_id
  if (selectedTeamId && selectableTeamIds.has(selectedTeamId) && !queryParamTeamIdStr) {
    return selectedTeamId
  }

  // if we already have a team don't redirect
  if (selectedTeamId && selectableTeamIds.has(selectedTeamId)) {
    return null
  } else if (queryParamTeam && selectableTeamIds.has(queryParamTeam.id)) {
    return null
  }

  // if we don't have an eligible team don't redirect
  if (!user || !selectableTeams?.length) {
    return null
  }

  const sortedTeams = sortUserTeams({ user, teams: selectableTeams })
  return sortedTeams[0].id
}

const useKitInstanceByID = (id) => {
  const { data: kitInstance, isFetching } = useKitInstance({ kitInstanceId: id })
  const { selectedTeamId, setSelectedTeamId } = useSelectedTeamId()

  useEffectAfterChange(() => {
    if (!selectedTeamId && kitInstance) {
      setSelectedTeamId(kitInstance.team_id)
    }
  }, [setSelectedTeamId, kitInstance, selectedTeamId])

  return { kitInstance, isFetching }
}

export default SelectedTeamContext

export { useSelectedTeam, useSelectedTeamId, useSelectedTeamIdWithTeamIdQueryParam, useKitInstanceByID }
