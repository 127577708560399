import { styled } from "styled-components"

const KalturaVideo = ({ className, videoId }) => (
  <div className={className}>
    <iframe
      id="kaltura_player"
      src={`https://cdnapisec.kaltura.com/${videoId}`}
      title="Kaltura video player"
      frameBorder="0"
      allow="autoplay *; fullscreen *; encrypted-media *"
      allowFullScreen
      className="video"
    ></iframe>
  </div>
)

export default styled(KalturaVideo)`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
