import { parseISO, toDate, format as formatDate } from "date-fns"

function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function getNumMinutesPassed(startTime) {
  return Math.floor(getNumSecondsPassed(startTime) / 60)
}

function getNumSecondsPassed(startTime) {
  // Accept integer (seconds) or Date as startTime:
  const startSeconds = Number.isInteger(startTime) ? startTime : startTime.getTime()
  return Math.floor((Date.now() - startSeconds) / 1000)
}

function getNumSecondsSinceEpoch() {
  return getNumSecondsPassed(0)
}

/**
 * Convert a timestamp (ISO or integer) to a date string.
 * @param {string|number} ts :: The timestamp to format; may be ISO-formatted
 *     datetime string, or positive integer milliseconds since epoch.
 * @param {string} [fmt='M/dd/yyyy'] :: The formatting pattern to use.
 */
function formatTimestampAsDate(ts, fmt = "M/dd/yyyy", { omitYearIfCurrent = false } = {}) {
  let date = null
  if (Number.isInteger(ts)) {
    date = toDate(ts)
  }
  if (typeof ts === "string") {
    date = parseISO(ts)
  }
  if (omitYearIfCurrent && date.getFullYear() === new Date().getFullYear()) {
    fmt = fmt.replace(/\/?y+\/?/i, "")
  }
  return date ? formatDate(date, fmt) : ""
}

export { getTimeZone, getNumMinutesPassed, getNumSecondsPassed, getNumSecondsSinceEpoch, formatTimestampAsDate }
