import cn from "classnames"
import { addSeconds, startOfDay, format as formatDate } from "date-fns"
import shuffle from "knuth-shuffle-seeded"
import { partition } from "lodash-es"
import { useRef, useState } from "react"
import { styled } from "styled-components"

import { useKitSession } from "../KitSessionContext"

import { StepNavigation } from "components/Steps"
import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getExerciseAnswer, getUserExerciseInstance } from "domains/Exercise/results_utils"
import { useSyncSessionEnabled } from "domains/KitSession/utils"
import { KIT_GROUP_SIZE } from "domains/LeaderKit/utils"
import { useHasTeamFeature } from "domains/Results/utils"
import { ArrowLeftIcon, ArrowRightIcon, ArrowsRepeatIcon } from "icons/FontAwesomeIcons"
import {
  useUpdateSelectedShareoutUser,
  useRealtimeSelectedShareoutUser,
  useKitParticipants,
} from "resources/monthly_kit"
import { sortUsersByShortName, useUser } from "resources/users"
import { Button, View, Loading } from "ui"
import { useEffectAfterFirstRender, useEffectAfterChange } from "ui/hooks"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import SelectedUserContext from "ui/SelectedUserContext"
import TimerWithRef from "ui/TimerWithRef"
import { REALTIME_KEY_PATH_PREFIX } from "utils/query"

const MiniKitSessionSelectedUsersShareView = ({ visibleAnswerIdentifiers, sessionStep, ...props }) => {
  const { kitInstance, team } = useKitSession()
  const isJumbo = kitInstance.kit.group_size === KIT_GROUP_SIZE.JUMBO
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { data: exerciseInstances } = useSessionExerciseInstances(kitInstance?.id, { enabled: isJumbo })
  const [shuffledParticipants, setShuffledParticipants] = useState()

  const randomSeed = sessionStep.path
  useEffectAfterChange(() => {
    if (!shuffledParticipants && participants && (!isJumbo || exerciseInstances)) {
      // For jumbo we want to show participants with answers first.
      // This doesn't get out of sync because in jumbo only the team lead's screen has the participants list.
      if (isJumbo) {
        const [participantsWithAnswers, participantsWithoutAnswers] = partition(participants, (participant) => {
          const exerciseInstance = getUserExerciseInstance(exerciseInstances, participant)
          return visibleAnswerIdentifiers.some((identifier) => getExerciseAnswer(exerciseInstance, identifier))
        })
        const shuffledWithAnswers = shuffle(sortUsersByShortName({ users: participantsWithAnswers }), randomSeed)
        const shuffledWithoutAnswers = shuffle(sortUsersByShortName({ users: participantsWithoutAnswers }), randomSeed)
        setShuffledParticipants([...shuffledWithAnswers, ...shuffledWithoutAnswers])
      } else {
        setShuffledParticipants(shuffle(sortUsersByShortName({ users: participants }), randomSeed))
      }
    }
  }, [participants, exerciseInstances, shuffledParticipants, randomSeed, visibleAnswerIdentifiers, isJumbo])

  if (!shuffledParticipants) {
    return null
  }

  return (
    <ViewWithParticipants
      team={team}
      shuffledParticipants={shuffledParticipants}
      sessionStep={sessionStep}
      {...props}
    />
  )
}

const ViewWithParticipants = ({
  team,
  shuffledParticipants,
  sessionStep,
  totalMinutes,
  minMinutesPerUser,
  maxMinutesPerUser,
  labelText = "Sharing now",
  navigateNextHandler,
  navigateBackHandler,
  nextButtonProps,
  backButtonProps,
  navRightContent,
  children,
  className,
  disableRealtimeShare,
  showUserTimer,
}) => {
  const componentRef = useRef(null)
  const timerRef = useRef()

  const teamSize = shuffledParticipants.length

  const timePerMemberMinutes = teamShareMemberTime({
    teamSize,
    totalMinutes: totalMinutes ?? sessionStep.minutes,
    minMinutesPerUser: minMinutesPerUser ?? 1,
    maxMinutesPerUser,
  })

  return (
    <div ref={componentRef}>
      <TimerWithRef
        ref={timerRef}
        TimerComponent={ShareOutBox}
        startSeconds={timePerMemberMinutes * 60}
        timerProps={{
          team,
          shuffledParticipants,
          timerRef,
          componentRef,
          sessionStep,
          totalMinutes,
          minMinutesPerUser,
          maxMinutesPerUser,
          labelText,
          navigateNextHandler,
          navigateBackHandler,
          nextButtonProps,
          backButtonProps,
          navRightContent,
          children,
          className,
          disableRealtimeShare,
          showUserTimer,
        }}
        className="mb-medium"
      />
    </div>
  )
}

const ShareOutBox = styled(function ShareOutBox({
  team,
  timerRef,
  componentRef,
  shuffledParticipants,
  sessionStep,
  durationSeconds,
  labelText,
  navigateNextHandler,
  navigateBackHandler,
  nextButtonProps: nextButtonPropsProp,
  backButtonProps,
  navRightContent,
  children,
  className,
  disableRealtimeShare,
  showUserTimer,
}) {
  const { data: user } = useUser({ userId: "me" })
  const { kitInstance } = useKitSession()
  const { enabled: realtimeShareoutUserActive } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_SHAREOUT_USER)
  const realtimeShareoutUserKey = REALTIME_KEY_PATH_PREFIX + sessionStep.path
  const { data: selectedShareoutUser, isInitialLoading } = useRealtimeSelectedShareoutUser({
    kitInstanceId: kitInstance?.id,
    key: realtimeShareoutUserKey,
    enabled: !!(kitInstance && realtimeShareoutUserActive),
  })
  const { mutateAsync: updateSelectedShareoutUser } = useUpdateSelectedShareoutUser({
    kitInstanceId: kitInstance?.id,
    key: realtimeShareoutUserKey,
  })
  const syncSessionEnabled = useSyncSessionEnabled() && !disableRealtimeShare

  const [selectedIndex, setSelectedIndex] = useState(0)
  const nextButtonProps =
    selectedIndex + 1 === shuffledParticipants.length
      ? { ...nextButtonPropsProp, secondary: false }
      : { ...nextButtonPropsProp, secondary: true }
  const selectedUserDeprecated = shuffledParticipants[selectedIndex]
  const isTeamLead = user.id === team.team_lead_id

  const selectedRealtimeUser = !!selectedShareoutUser ? selectedShareoutUser : shuffledParticipants[0]
  const selectedUser =
    realtimeShareoutUserActive && syncSessionEnabled && !isTeamLead ? selectedRealtimeUser : selectedUserDeprecated

  useEffectAfterFirstRender(() => {
    setTimeout(() => {
      timerRef.current?.play?.()
    }, 5000) // for the first user start the timer after 5 seconds
  })

  useEffectAfterChange(() => {
    if (!!realtimeShareoutUserActive && !!syncSessionEnabled) {
      const selectedShareoutUserIndex = shuffledParticipants.findIndex(({ id }) => id === selectedShareoutUser?.id)
      if (selectedShareoutUserIndex !== -1 && selectedShareoutUserIndex !== selectedIndex) {
        setSelectedIndex(selectedShareoutUserIndex)
      }
      timerRef?.current?.resetAndPlay?.()
      componentRef?.current?.scrollIntoView()
    }
  }, [
    selectedIndex,
    selectedShareoutUser,
    realtimeShareoutUserActive,
    syncSessionEnabled,
    shuffledParticipants,
    componentRef,
    timerRef,
  ])

  const onSelectIndex = (index) => {
    if (index >= shuffledParticipants.length) {
      index = 0
    } else if (index < 0) {
      index = shuffledParticipants.length - 1
    }
    if (!realtimeShareoutUserActive || !syncSessionEnabled) {
      setSelectedIndex(index)
      timerRef?.current?.resetAndPlay?.()
      componentRef?.current?.scrollIntoView()
    }
    realtimeShareoutUserActive &&
      isTeamLead &&
      syncSessionEnabled &&
      updateSelectedShareoutUser(shuffledParticipants[index].id)
  }

  const showTimer = !!user && (user.id === selectedUser?.id || user.id === team.team_lead_id) && !!showUserTimer
  const reachedEnd = selectedIndex + 1 === shuffledParticipants.length

  let bgColor = "blue-tint"
  let timerColor = "rising-green"
  if (!!showTimer) {
    if (durationSeconds <= 15 && durationSeconds > 1) {
      bgColor = "yellow-tint"
      timerColor = "rising-orange"
    } else if (durationSeconds <= 1) {
      bgColor = "orange-tint"
      timerColor = "danger"
    }
  }
  if (realtimeShareoutUserActive && isInitialLoading) {
    return <Loading />
  }

  return (
    <div ref={componentRef} className={className}>
      <div className="border border-radius-small lift-4 share-out-box">
        <div
          className={cn(
            "selected-user-box",
            "border-top-radius-small",
            `bg-${bgColor}`,
            "px-medium",
            "pt-medium",
            "pb-xs",
            "full-width"
          )}
        >
          <h4 className="text-gray-8 text-semi-bold">{labelText}</h4>
          <View $justifyContent="space-between" $alignItems="center">
            <h2 className="text-semi-bold">{selectedUser.short_name}</h2>
            <ShareTimer durationSeconds={durationSeconds} showTimer={showTimer} timerColor={timerColor} />
          </View>
        </div>

        {!!selectedUser && (
          <div className="p-medium">
            <SelectedUserContext.Provider value={{ selectedUser }}>{children}</SelectedUserContext.Provider>
          </div>
        )}
        {(!realtimeShareoutUserActive || !syncSessionEnabled || !!isTeamLead) && (
          <div className="px-medium-mobile-only px-xxxxl-mobile-never pb-medium">
            <View className="mb-small">
              <Button
                className={cn("text-semi-bold", { tertiary: reachedEnd })}
                onClick={() => onSelectIndex(selectedIndex - 1)}
              >
                <ArrowLeftIcon />
              </Button>
              <Button
                className={cn("flex-grow-1 text-semi-bold", { tertiary: reachedEnd })}
                onClick={() => onSelectIndex(selectedIndex + 1)}
              >
                {!!reachedEnd ? (
                  <>
                    <div className="text-semi-bold">Start over</div>
                    <ArrowsRepeatIcon />
                  </>
                ) : (
                  <>
                    <div className="text-semi-bold">Next person</div>
                    <ArrowRightIcon />
                  </>
                )}
              </Button>
            </View>
            <div className="text-center text-small text-thin text-italic text-gray-7">
              {selectedIndex + 1} of {shuffledParticipants.length} {!!reachedEnd && " - All done!"}
            </div>
          </div>
        )}
      </div>
      <StepNavigation
        nextButtonProps={nextButtonProps}
        backButtonProps={backButtonProps}
        onNext={navigateNextHandler}
        onBack={navigateBackHandler}
        rightContent={navRightContent}
      />
    </div>
  )
})`
  max-width: 600px;
  margin: auto;

  .selected-user-box {
    max-width: 100%;
    overflow: hidden;
  }
`

const ShareTimer = styled(function ShareTimer({ durationSeconds, showTimer, timerColor, className }) {
  if (!showTimer) {
    return null
  }

  const formattedTime = formatDate(addSeconds(startOfDay(new Date()), durationSeconds), "m:ss")

  return (
    <span className={cn(className, `text-center border border-${timerColor} border-radius-xs bg-white`)}>
      <h3 className={`text-semi-bold text-${timerColor}`}>{formattedTime}</h3>
    </span>
  )
})`
  width: 77px;
`

const teamShareMemberTime = ({ teamSize, totalMinutes, maxMinutesPerUser, minMinutesPerUser }) => {
  if (!totalMinutes) {
    return minMinutesPerUser
  }

  const calculatedMinutes = Math.floor(totalMinutes / teamSize)
  if (maxMinutesPerUser && calculatedMinutes > maxMinutesPerUser) {
    return maxMinutesPerUser
  }
  if (calculatedMinutes < minMinutesPerUser) {
    return minMinutesPerUser
  }
  return calculatedMinutes
}

export default MiniKitSessionSelectedUsersShareView
