import * as Sentry from "@sentry/browser"
import { isIOS, isAndroid } from "react-device-detect"
import "firebaseui/dist/firebaseui.css"

import { getNumSecondsSinceEpoch } from "utils/date"
import { isStagingEnv } from "utils/env"
import { safeDynamicImport } from "utils/import"
import { StorageKey } from "utils/storage"
import { buildUrl } from "utils/string"

// Firebase Auth Token Management Strategy
// ---------------------------------------
// For Firebase Auth'd users we need to add user ID tokens to every request in the
// form of an "Authorization: 'Bearer <token>'" header, but since we only want
// _some_ users to authenticate with Firebase Auth, this poses a problem:
//
// If a user isn't using Firebase Auth at authentication, we don't want
// their requests to rely on the Firebase Auth SDK at all, and we definitely don't
// want their requests to await for Firebase Auth to initialize and get the latest
// refreshed user ID token.
//
// To deal with this, whenever a user is signed in via Firebase Auth, we persist
// the latest Firebase Auth user ID token to localStorage, with an expiry date
// attached matching the time it will expire on the Firebase side (1 hour from
// refresh). If the a token does not exist in localStorage, our request code knows
// that a user is not authed via Firebase and will never try to refresh a token.
// Upon sign-out, the token is removed entirely from localStorage.
//
// On page reload, we can immediately send the persisted tokens with requests so
// authentication will work without waiting for the Firebase Auth SDK to initialize
// (and will continue to work after the SDK is fully initialized).

const FIREBASE_AUTH_TOKEN_EXPIRY_SECONDS = 55 * 60 // 1 hour minus 5 minute buffer
// 1 hour is the default token expiry period, see:
// https://firebase.google.com/docs/auth/admin/manage-sessions

// Private util functions:
function _isTokenExpired(expiry) {
  return !Number.isInteger(expiry) || expiry < getNumSecondsSinceEpoch()
}
function _persistFirebaseUserIdTokenWithNewExpiry(token) {
  window.localStorage.setItem(
    StorageKey.FirebaseAuthToken,
    JSON.stringify({
      token,
      expiry: getNumSecondsSinceEpoch() + FIREBASE_AUTH_TOKEN_EXPIRY_SECONDS,
    })
  )
}
function _persistFirebaseUserName(user) {
  const name = user?.displayName?.trim() ?? ""
  if (name) {
    window.sessionStorage.setItem(StorageKey.FirebaseAuthUserName, name)
    // Use sessionStorage so this will be cleaned up naturally when tab closes.
    // We don't need to persist it any longer than that since it's just used
    // for pre-populating input fields during user account creation.
  }
}

// TODO: jey: remove after we switch all sso providers to same domain
function isUsingSameAuthDomain() {
  const url = new URL(window.location)
  return url.searchParams.get("useSameAuthDomain") === "true"
}

// TODO: jey: remove after we switch all sso providers to same domain
function redirectWithSameAuthDomainIfNeeded({ features, provider, next }) {
  if (isUsingSameAuthDomain()) {
    return false
  }

  const flagName = `use_same_auth_domain_${provider}`
  const shouldUseSameAuthDomain = !!features[flagName]
  if (!shouldUseSameAuthDomain) {
    return false
  }

  const url = new URL(
    buildUrl([window.location.origin, "auth", "sso", provider], {
      urlQueryParams: { next, useSameAuthDomain: "true" },
    })
  )
  window.location = url
  return true
}

// Set up a promise which allows various functions to delay operations
// until Firebase App initialization is complete.
// (getFirebaseAuthInstance, getFirebaseRealtimeDatabase)
let _firebaseAppInstancePromiseResolve = null
const _firebaseAppInstancePromise = new Promise((resolve) => {
  _firebaseAppInstancePromiseResolve = resolve
})
function untilFirebaseAppInitialization() {
  return _firebaseAppInstancePromise
}
function finishFirebaseAppInitialization(firebaseAppInstance) {
  _firebaseAppInstancePromiseResolve(firebaseAppInstance)
}

async function initializeFirebaseApp() {
  const authDomain = isUsingSameAuthDomain() ? window.location.hostname : import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN

  // Tests may not have access to VITE_APP_FIREBASE_API_KEY, in which case we'll
  // skip Firebase Auth initialization entirely. If we do want to test flows that
  // involve Firebase auth, we'll set up the auth emulator to do so.
  // https://firebase.google.com/docs/emulator-suite/connect_auth
  if (import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    const firebase = await safeDynamicImport(() => import("firebase/app"), ["initializeApp"])

    if (!firebase?.initializeApp) {
      return // Dynamic import failed; page will reload imminently.
    }

    const firebaseAppInstance = firebase.initializeApp({
      apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
      authDomain,
      projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
      databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASE_URL,
    })

    if (["development", "test"].includes(import.meta.env.VITE_NODE_ENV)) {
      const firebaseDB = await safeDynamicImport(
        () => import("firebase/database"),
        ["getDatabase", "connectDatabaseEmulator"]
      )
      if (firebaseDB?.connectDatabaseEmulator && firebaseDB?.getDatabase) {
        firebaseDB.connectDatabaseEmulator(firebaseDB.getDatabase(), "localhost", 9000)
      }
    }

    // Notify other functions that Firebase App initialization is complete:
    // (getFirebaseAuthInstance, getFirebaseRealtimeDatabase)
    finishFirebaseAppInitialization(firebaseAppInstance)
  }
}

async function getFirebaseAuthInstance() {
  if (!import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    return null // in test environment, skip Firebase integration entirely; see comment above
  }

  const firebase = await safeDynamicImport(() => import("firebase/auth"), ["getAuth"])

  if (!firebase?.getAuth) {
    return null // Dynamic import failed; page will reload imminently.
  }

  await untilFirebaseAppInitialization()

  return firebase.getAuth()
}

// Get the latest Firebase user ID token from localStorage, to be used in the
// "Authorization" request header for API requests.
// This function will _not_ refresh the token automatically if it is expired;
// instead it provides a { token, expired } return value and the caller must
// check `expired` and refresh the token separately if necessary.
function getFirebaseAuthInfo() {
  const strValue = window.localStorage.getItem(StorageKey.FirebaseAuthToken)
  let parsedValue = null
  try {
    parsedValue = JSON.parse(strValue)
  } catch (e) {
    // Pass, handle invalid persisted token value below.
  }

  const token = parsedValue?.token ?? null
  const expiry = parsedValue?.expiry ?? null

  if (!token || !Number.isInteger(expiry)) {
    // Token was invalid or missing - clear value from persistence:
    window.localStorage.removeItem(StorageKey.FirebaseAuthToken)
  }

  return {
    token,
    expired: token ? _isTokenExpired(expiry) : false,
    isAuthenticatedWithFirebase: !!token,
    // presence of token in localStorage indicates user is authed via Firebase
    firebaseUserDisplayName: window.sessionStorage.getItem(StorageKey.FirebaseAuthUserName) ?? "",
  }
}

// Run a function on the _next_ auth-state-change event only,
// and don't run it for any subsequent auth-state-change events after that:
async function onNextAuthStateChangedOnly(func) {
  const firebase = await safeDynamicImport(() => import("firebase/auth"), ["onAuthStateChanged"])

  if (!firebase?.onAuthStateChanged) {
    return // Dynamic import failed; page will reload imminently.
  }

  const unsubscribeOnAuthStateChanged = firebase.onAuthStateChanged(await getFirebaseAuthInstance(), (user) => {
    // Remove handler immediately to ensure that it's only ever executed once per call:
    unsubscribeOnAuthStateChanged()
    return func(user)
  })
}

// Force a refresh the Firebase user ID token, and return a promise which
// resolves to the refreshed token - which will expire in 1 hour.
// Also sets the latest token and new expiry in localStorage.
function refreshFirebaseUserIdToken(token) {
  const previousToken = token ?? getFirebaseAuthInfo().token
  return new Promise((resolve) => {
    onNextAuthStateChangedOnly(async (user) => {
      if (!user) {
        return resolve(null)
      }

      const forceTokenRefresh = true // force refresh so we know next expiry time
      let refreshedToken

      const firebase = await safeDynamicImport(() => import("firebase/auth"), ["getIdToken"])

      if (!firebase?.getIdToken) {
        return // Dynamic import failed; page will reload imminently.
      }

      try {
        refreshedToken = await firebase.getIdToken(user, forceTokenRefresh)
      } catch (err) {
        if (/firebase.*\(auth\/network-request-failed\)/i.test(err.message)) {
          // Gracefully handle "network request failed" errors since these can
          // happen arbitrarily when the network connection is interrupted.
          // This will generally result in a 401 (unauthenticated) request response
          // at which point our system will retry the token refresh and request.
          console.warn(err.message)
          return resolve(null)
        } else {
          throw err
        }
      }

      if (!refreshedToken) {
        throw new Error("firebase.js: getIdToken unexpectedly did not produce a token")
      }

      const currentToken = getFirebaseAuthInfo().token

      // Ensure authed state hasn't changed since we started awaiting latest token:
      if (previousToken === currentToken) {
        _persistFirebaseUserIdTokenWithNewExpiry(refreshedToken)
        return resolve(refreshedToken)
      } else {
        return resolve(currentToken)
      }
    })
  })
}

// Get the `Authorization: "Bearer <token>"` value needed to
// authenticate request against our backend via Firebase Auth.
// Returns null if the current user doesn't use Firebase Auth.
// Otherwise, returns a promise which resolves to the header key/value with
// correct token, after awaiting refresh of the token if necessary.
function getFirebaseAuthRequestHeaderPromise({ forceTokenRefresh = false } = {}) {
  let { isAuthenticatedWithFirebase, token, expired } = getFirebaseAuthInfo()
  if (!isAuthenticatedWithFirebase) {
    return null
  } else {
    return new Promise(async (resolve) => {
      if (isAuthenticatedWithFirebase) {
        if (expired || forceTokenRefresh) {
          // Attempt to refresh the Firebase user ID token if it was expired,
          // or if this is a retry due to request auth failure:
          try {
            token = await refreshFirebaseUserIdToken(token)
          } catch (e) {
            Sentry.captureException(e)
            token = null
          }
        }
        if (token) {
          resolve({ Authorization: `Bearer ${token}` })
        } else {
          resolve({})
        }
      }
    })
  }
}

// Sign out the current user from Firebase Auth, and clear ID token from localStorage.
async function signOutFirebaseUser() {
  window.localStorage.removeItem(StorageKey.FirebaseAuthToken)
  const firebaseAuth = await getFirebaseAuthInstance()

  if (firebaseAuth) {
    const firebase = await safeDynamicImport(() => import("firebase/auth"), ["signOut"])

    if (!firebase?.signOut) {
      return // Dynamic import failed; page will reload imminently.
    }

    await firebase.signOut(firebaseAuth)
  }
}

const originalFetch = window.fetch
function unpatchInterceptedWindowFetch() {
  window.fetch = originalFetch
}
function interceptFirebaseAuthIDTokenFetch() {
  // Return promise which resolves to a valid Firebase Auth user ID token.
  // This temporarily patches window.fetch to retrieve the token from the earliest
  // Firebase Auth response coming through which has the token in response JSON.
  return new Promise((resolve) => {
    window.fetch = function (...args) {
      const [url, options] = args
      return originalFetch(...args).then((response) => {
        if (options?.method === "POST" && url?.startsWith("https://identitytoolkit.googleapis.com/")) {
          response
            .clone() // Must clone response before reading .json(), otherwise
            .json() // subsequent reads of .json() will return nothing.
            .then((json) => {
              const idToken = json?.idToken ?? null
              if (idToken) {
                // Once we've received token, we don't need fetch-intercept any more:
                unpatchInterceptedWindowFetch()
                resolve(idToken)
              }
            })
        }
        return response
      })
    }
  })
}
let interceptFirebaseAuthIDTokenPromise = null
if (isStagingEnv() && (isIOS || isAndroid)) {
  // fetch-intercept is Android+Staging only for now
  // Firebase Auth ID token intercept must be initialized here, otherwise the
  // Firebase JS won't end up making requests with the patched fetch function.
  interceptFirebaseAuthIDTokenPromise = interceptFirebaseAuthIDTokenFetch()
}

// Initialize FirebaseUI using the provided HTML id as container element.
// `onSignIn` may be provided to execute code after user successfully auths.
async function initializeFirebaseUI(authContainerId, { ssoProviders = [], ssoRedirectLogin = false, onSignIn = null }) {
  console.info("[Firebase 0ms] Starting Firebase UI.")

  const startMs = Date.now()
  const time = () => Date.now() - startMs
  let isSignInComplete = false
  let currentUser = null
  let currentToken = null

  function completeSignInWithToken(token) {
    console.info(`[Firebase ${time()}ms] Recieved user ID token, completing sign in... (${token})`)
    if (!isSignInComplete && token && !currentToken) {
      // Once we've received token, we don't need fetch-intercept any more:
      unpatchInterceptedWindowFetch()
      currentToken = token
      isSignInComplete = true
      _persistFirebaseUserIdTokenWithNewExpiry(token)
      onSignIn?.()
      console.info(`[Firebase ${time()}ms] Sign in completed.`)
    }
  }

  function completeSignInWithUser(user) {
    if (!isSignInComplete && user) {
      _persistFirebaseUserName(user)
      console.info(`[Firebase ${time()}ms] Getting user ID token...`)
      if (!currentToken) {
        user.getIdToken().then(completeSignInWithToken)
      }
    }
  }

  // Try and get the necessary Firebase Auth ID token as soon as possible via
  // Firebase Auth response intercept. This will sometimes be ~30-60 seconds faster
  // than waiting for the normal signInSuccessWithAuthResult or onAuthStateChanged.
  interceptFirebaseAuthIDTokenPromise?.then(completeSignInWithToken)

  const uiConfig = {
    signInFlow: ssoRedirectLogin ? "redirect" : "popup",
    signInOptions: ssoProviders,
    tosUrl: "https://risingteam.com/terms",
    privacyPolicyUrl: "https://risingteam.com/privacy",
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        if (!currentUser && authResult.user) {
          console.info(`[Firebase ${time()}ms] signInSuccessWithAuthResult triggered for user:`, authResult.user)
          currentUser = authResult.user
          completeSignInWithUser(authResult.user)
        }
        return false
        // Return false to prevent redirect here; our component code
        // will redirect to "next" query param if it was specified.
      },
    },
  }

  const firebaseAuth = await getFirebaseAuthInstance()
  const firebaseUI = await safeDynamicImport(() => import("firebaseui"), ["auth"])

  if (!firebaseUI?.auth) {
    return null // Dynamic import failed; page will reload imminently.
  }

  const ui = firebaseUI.auth.AuthUI.getInstance() ?? new firebaseUI.auth.AuthUI(firebaseAuth)

  let isFirebaseUIStarted = false
  const firebaseUIStartTimeouts = []

  function delayedStartFirebaseUI({ delayMs = 0 } = {}) {
    if (!isFirebaseUIStarted && !isSignInComplete) {
      firebaseUIStartTimeouts.push(
        setTimeout(() => {
          firebaseUIStartTimeouts.forEach(clearTimeout)
          // Avoid starting FirebaseUI multiple times:
          if (!isFirebaseUIStarted && !isSignInComplete) {
            isFirebaseUIStarted = true
            ui.start(`#${authContainerId}`, uiConfig)
          }
        }, delayMs)
      )
    }
  }

  // FirebaseUI is started within onAuthStateChanged callback below, to ensure it
  // starts after Firebase Auth instance is initialized. Sometimes this callback
  // isn't called as expected, so also initialize after 500ms if we haven't already.
  delayedStartFirebaseUI({ delayMs: 500 })
  // 500ms delay here helps prevent FirebaseUI from getting into "stuck" state

  // Register "backup" completeSignInWithUser handler to help avoid long delays before
  // FirebaseUI calls signInSuccessWithAuthResult on some mobile devices:
  const firebase = await safeDynamicImport(() => import("firebase/auth"), ["onAuthStateChanged"])

  if (!firebase?.onAuthStateChanged) {
    return // Dynamic import failed; page will reload imminently.
  }

  const unsubscribeOnAuthStateChanged = firebase.onAuthStateChanged(firebaseAuth, (user) => {
    // If no user is authed, start firebase UI:
    if (!user) {
      delayedStartFirebaseUI({ delayMs: 250 })
      // 250ms delay here helps prevent FirebaseUI from getting into "stuck" state
    }

    // If user is authed, finish sign-in process:
    if (user && !currentUser) {
      console.info(`[Firebase ${time()}ms] onAuthStateChanged triggered for user:`, user)
      currentUser = user
      completeSignInWithUser(user)

      // After sign-in successfully completed, remove handler so we don't run it again:
      unsubscribeOnAuthStateChanged()
    }
  })

  return ui
}

async function getFirebaseRealtimeDatabase() {
  // in test environment, skip Firebase integration entirely; see comment above
  if (!import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    return { database: null, ref: null, onValue: null }
  }

  const firebaseDB = await safeDynamicImport(() => import("firebase/database"), ["getDatabase", "ref", "onValue"])

  await untilFirebaseAppInitialization()

  return {
    database: firebaseDB?.getDatabase?.() ?? null,
    ref: firebaseDB?.ref ?? null,
    onValue: firebaseDB?.onValue ?? null,
  }
}

export {
  initializeFirebaseApp,
  untilFirebaseAppInitialization,
  initializeFirebaseUI,
  getFirebaseAuthInfo,
  refreshFirebaseUserIdToken,
  getFirebaseAuthRequestHeaderPromise,
  signOutFirebaseUser,
  getFirebaseRealtimeDatabase,
  isUsingSameAuthDomain,
  redirectWithSameAuthDomainIfNeeded,
}
