import KitsTeamDetails from "components/KitsTeamDetails"
import { View } from "ui"

const TeamDetailsPanel = ({ TeamSelectorComponent, selectedTeam, onTeamChange, user, showCreateNewTeam = false }) => (
  <View
    $flexDirectionMobile="column"
    $flexDirectionTablet="column"
    $alignItemsMobile="flex-start"
    $alignItemsTablet="flex-start"
    $alignItems="center"
    $gap="24px"
    className="my-medium"
  >
    <TeamSelectorComponent
      selectedTeam={selectedTeam}
      onTeamChange={onTeamChange}
      user={user}
      showSingleTeam={true}
      showCreateNewTeam={showCreateNewTeam}
    />
    <KitsTeamDetails selectedTeam={selectedTeam} user={user} />
  </View>
)

export default TeamDetailsPanel
