import { HeartIcon, RocketLaunchIcon } from "icons/FontAwesomeIcons"

const KIT_TYPE = {
  KIT: "kit",
  BOOST: "boost",
  MINI: "mini",
}

// Keep in sync with backend/monthly_kit/definitions/constants.py
const CUSTOM_KIT_ORGANIZATION = {
  BOH: "boh",
}

const KIT_GROUP_SIZE = {
  STANDARD: "standard",
  JUMBO: "jumbo",
}

const KIT_LINK_KEYS = {
  SESSION_URL: "session_url",
  SESSION_PREVIEW_URL: "session_preview_url",
  SESSION_START_URL: "session_start_url",
  HOME_URL: "home_url",
  PREP_URL: "prep_url",
  REVIEW_URL: "review_url",
  RESULTS_URL: "results_url",
  BONUS_URL: "bonus_url",
  TEAM_HOME_URL: "team_home_url",
}

const PROMO_KIT_SLUG = {
  GRATITUDE: "gratitude",
}

const CUSTOM_KIT_LOGO_URL = "https://static.risingteam.com/kit_assets/custom-kit-organization-logo/"

function getKitTypeInfo(kitType) {
  switch (kitType) {
    case KIT_TYPE.BOOST:
      return {
        header: "Connection Kit",
        showLearnedSomethingValuableQuestion: true,
        showFeelMoreConnectedQuestion: true,
        showWorkMoreEffectivelyQuestion: false,
        showLikelihoodOfRecommendingQuestion: true,
        showThinkAboutSessionQuestion: false,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: false,
        hasReflections: false,
        hasReportExerciseResponses: false,
        pillIcon: HeartIcon,
        pillText: "Connection Kit",
        pillColorCss: "text-orange-3 border-orange-3",
      }
    case KIT_TYPE.MINI:
      return {
        header: "Mini",
        showLearnedSomethingValuableQuestion: false,
        showFeelMoreConnectedQuestion: false,
        showWorkMoreEffectivelyQuestion: false,
        showLikelihoodOfRecommendingQuestion: false,
        showThinkAboutSessionQuestion: true,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: false,
        hasReflections: false,
        hasReportExerciseResponses: false,
        pillIcon: HeartIcon,
        pillText: "Mini Kit",
        pillColorCss: "text-orange-3 border-orange-3",
      }
    default:
      return {
        header: "Theme",
        showLearnedSomethingValuableQuestion: true,
        showFeelMoreConnectedQuestion: true,
        showWorkMoreEffectivelyQuestion: true,
        showLikelihoodOfRecommendingQuestion: true,
        showThinkAboutSessionQuestion: false,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: true,
        hasReflections: true,
        hasReportExerciseResponses: true,
        pillIcon: RocketLaunchIcon,
        pillText: "Development Kit",
        pillColorCss: "text-rising-green border-rising-green",
      }
  }
}

function getCustomKitTypeInfo(customKitOrganization) {
  switch (customKitOrganization) {
    case CUSTOM_KIT_ORGANIZATION.BOH:
      return {
        customPillLogo: CUSTOM_KIT_LOGO_URL + "boh-logo.svg",
        pillText: "Bank of Hawaiʻi",
        pillColorCss: "text-blue-2 border-blue-2 bg-blue-tint",
      }
    default:
      return {
        customPillLogo: null,
        pillText: "Development Kit",
        pillColorCss: "text-rising-green border-rising-green",
      }
  }
}

function roundKitSessionTime(sessionMinutes) {
  // Note: Rounding logic must match rounded_session_time_minutes in monthly_kit/definitions/base.py
  // Rounding Scheme:
  // Round down to nearest 15-min mark if less than 5 min above that 15-min mark.
  // Otherwise round up to next 15-min mark. Eg. 63->60 64->60 65->75 67->75 etc.
  const remainder = sessionMinutes % 15
  return remainder < 5 ? sessionMinutes - remainder : sessionMinutes + 15 - remainder
}

function formatKitSessionTime(sessionMinutes, { unit = "minutes" } = {}) {
  return `${roundKitSessionTime(sessionMinutes)} ${unit}`
}

export {
  KIT_TYPE,
  KIT_GROUP_SIZE,
  KIT_LINK_KEYS,
  PROMO_KIT_SLUG,
  getKitTypeInfo,
  getCustomKitTypeInfo,
  formatKitSessionTime,
  roundKitSessionTime,
}
