import fileDownload from "js-file-download"

import { Table } from "components"
import { KIT_TYPE } from "domains/LeaderKit/utils"
import {
  getKitUsageKitsExport,
  getKitUsageTeamKitsExport,
  useAccount,
  useKitUsageKitsReportData,
} from "resources/billing"
import { Button, Loading, HorizontalRule, View, colors } from "ui"
import { generateListTooltipTitle } from "ui/Tooltip"

const KitUsageKitsReport = ({
  className,
  accountId,
  accountTags,
  multiAccountIds,
  kitSlug,
  teamId,
  teamTags,
  includeArchivedTeams,
}) => {
  const { data: kitUsageKitsData, isFetching } = useKitUsageKitsReportData({
    accountId,
    accountTags,
    multiAccountIds,
    kitSlug,
    teamId,
    teamTags,
    includeArchivedTeams,
  })

  // TODO(evnp): should this work with multiple accounts and account tags?
  const { data: account, isFetching: isFetchingAccount } = useAccount(accountId)

  if ((!kitUsageKitsData && isFetching) || (!account && isFetchingAccount)) {
    return <Loading />
  }

  const isConnectAccount = account?.is_connect_account

  const downloadKitsCSVExport = async () => {
    const csvExport = await getKitUsageKitsExport({
      accountId,
      accountTags,
      multiAccountIds,
      kitSlug,
      teamId,
      teamTags,
      includeArchivedTeams,
    })
    fileDownload(csvExport, "kits_export.csv")
  }

  const downloadTeamKitsCSVExport = async () => {
    const csvExport = await getKitUsageTeamKitsExport({
      accountId,
      accountTags,
      multiAccountIds,
      kitSlug,
      teamId,
      teamTags,
      includeArchivedTeams,
    })
    fileDownload(csvExport, "team_kits_export.csv")
  }

  return (
    <div className={className}>
      <HorizontalRule />
      {!!kitUsageKitsData ? (
        <View $flexDirection="row" $justifyContent="space-between" className="mb-medium">
          <View $flexDirection="row" $alignItems="center">
            <h3 className="mr-medium">Kits</h3>
            {!isConnectAccount && (
              <Button color={colors.risingBlue} className="link-semi-bold" onClick={downloadKitsCSVExport}>
                Download CSV
              </Button>
            )}
          </View>
          <View $flexDirection="row" $justifyContent="right" $alignItems="center">
            <h3 className="mr-medium">Team + Kits</h3>
            {!isConnectAccount && (
              <Button color={colors.risingBlue} className="link-semi-bold" onClick={downloadTeamKitsCSVExport}>
                Download CSV
              </Button>
            )}
          </View>
        </View>
      ) : (
        <h3 className="mb-medium">Kits</h3>
      )}
      <Table
        className={className}
        rows={kitUsageKitsData?.kits ?? []}
        empty="No kit usage data available yet."
        notFound="No matching data"
        maxColumnWidth={250}
        columns={[
          {
            id: "type",
            Header: "Kit type",
            accessor: (kit) =>
              kit.type === KIT_TYPE.KIT ? "Development" : kit.type === KIT_TYPE.BOOST ? "Connection" : "Mini",
          },
          {
            id: "title",
            Header: "Kit title",
            accessor: "title",
          },
          {
            id: "completed_teams_count",
            Header: "Teams completed",
            accessor: "completed_teams_count",
            tooltip: (kit) =>
              kit.completed_teams_count > 0
                ? generateListTooltipTitle(kit.first_20_completed_teams, 20, kit.completed_teams_count)
                : null,
          },
          {
            id: "scheduled_teams_count",
            Header: "Teams scheduled",
            accessor: "scheduled_teams_count",
            tooltip: (kit) =>
              kit.scheduled_teams_count > 0
                ? generateListTooltipTitle(kit.first_20_scheduled_teams, 20, kit.scheduled_teams_count)
                : null,
          },
          {
            id: "not_scheduled_teams_count",
            Header: "Teams not scheduled",
            accessor: "not_scheduled_teams_count",
            tooltip: (kit) =>
              kit.not_scheduled_teams_count > 0
                ? generateListTooltipTitle(kit.first_20_not_scheduled_teams, 20, kit.not_scheduled_teams_count)
                : null,
          },
        ]}
      />
    </div>
  )
}

export default KitUsageKitsReport
