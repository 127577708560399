import { styled } from "styled-components"

import { LineRatingField } from "forms/fields"

const ExerciseLineRatingField = styled(function ExerciseLineRatingField({
  name,
  labels,
  aria_labels,
  num_options = 10,
  show_numbers = true,
  ...initialProps
}) {
  // options and label are destructured to avoid passing them to LineRatingField
  // since they are only being used for results block
  const { options: _options = null, label: _label = null, ...props } = initialProps
  return (
    <LineRatingField
      numOptions={num_options}
      name={name}
      axisLabels={labels}
      ariaLabels={aria_labels}
      className="mb-xxxxl"
      showNumbers={show_numbers}
      {...props}
    />
  )
})`
  width: ${({ num_options }) => (num_options < 6 ? "70%" : "100%")};

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    width: 100%;
  }
`

export default ExerciseLineRatingField
