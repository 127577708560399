import { useParams } from "react-router-dom"

import BlockShareResults from "./BlockShareResults"
import CareerHorizonsShareResults from "./CareerHorizonsShareResults"
import CrisisResponseShareResults from "./CrisisResponseShareResults"
import LearnerAgilityShareResults from "./LearnerAgilityShareResults"
import TalentsShareResults from "./TalentsShareResults"

import { NotFound } from "components"
import { useSharedExerciseInstance } from "domains/Exercise/resource"
import { Loading, View } from "ui"

const SLUG_TO_REVIEW_COMPONENT = {
  talents: TalentsShareResults,
  "career-horizons": CareerHorizonsShareResults,
  "learner-agility": LearnerAgilityShareResults,
  "crisis-response": CrisisResponseShareResults,
}

const ShareResultsPage = () => {
  const { share_code } = useParams()
  const { data: exerciseInstance, isFetching } = useSharedExerciseInstance({ share_code })

  if (!exerciseInstance && isFetching) {
    return <Loading />
  }

  if (!exerciseInstance) {
    return <NotFound />
  }

  const shareDefinition = exerciseInstance.definition.share_results

  const ResultsPage = shareDefinition ? BlockShareResults : SLUG_TO_REVIEW_COMPONENT[exerciseInstance.slug]
  return (
    <View $flexDirection="column" className="p-xxl">
      <ResultsPage exerciseInstance={exerciseInstance} />
    </View>
  )
}

export default ShareResultsPage
