import cn from "classnames"
import { Link, useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import { KIT_STATUS, getKitStatus } from "domains/LeaderKit/KitsTableofContents/utils"
import {
  getMiniKitStatusIcon,
  getMiniSubTypeContent,
  MINI_SUB_TYPE,
} from "domains/MiniKit/MiniKitsTableofContents/utils"
import { useUpdateOrCreateKitInstance, useRestartKitSessionTestOnly, useStartKitSession } from "resources/monthly_kit"
import { Button, View, colors } from "ui"
import { useFeatures } from "ui/hooks"
import { FLAGS } from "ui/hooks/useFeatures"

const MiniKitTile = ({
  kitInfo,
  kitInstance,
  user,
  selectedTeam,
  initiallyExpanded,
  className,
  isScheduleModeActive,
  isCurrentTeamLead,
  isSetupStep = false,
}) => (
  <MiniKitTileContents
    kitInfo={kitInfo}
    kitInstance={kitInstance}
    user={user}
    selectedTeam={selectedTeam}
    initiallyExpanded={initiallyExpanded}
    className={className}
    isScheduleModeActive={isScheduleModeActive}
    isCurrentTeamLead={isCurrentTeamLead}
    isSetupStep={isSetupStep}
  />
)

// KitTileContents is split into its own component so we can pass kitStatusConfig
// as a prop to access it in styled-components css
const MiniKitTileContents = styled(function MiniKitTileContents({
  kitInfo,
  kitInstance,
  selectedTeam,
  className,
  isScheduleModeActive,
  isCurrentTeamLead,
  isSetupStep,
}) {
  const { mutateAsync: startKitSession } = useStartKitSession()
  const { mutateAsync: createKitInstance } = useUpdateOrCreateKitInstance({
    slug: kitInfo.slug,
    teamId: selectedTeam.id,
  })
  const { mutateAsync: restartKitSessionTestOnly } = useRestartKitSessionTestOnly()
  const navigate = useNavigate()
  const kitStatus = getKitStatus(kitInstance, kitInfo)
  const kitStatusIcon = getMiniKitStatusIcon(kitStatus)
  const miniSubTypeContent = getMiniSubTypeContent(kitInfo.sub_type)
  const isGuessingGameMini = kitInfo.sub_type === MINI_SUB_TYPE.GAME

  const onSessionStartClick = async () => {
    if (!kitInstance) {
      kitInstance = await createKitInstance(kitInfo.slug, selectedTeam.id)
    }
    await startKitSession({ kitInstanceId: kitInstance.id })
    navigate(kitInstance.session_url)
  }

  const onPreviewSessionClick = async () => {
    if (!kitInstance) {
      kitInstance = await createKitInstance()
    }
    navigate(kitInstance?.session_preview_url)
  }

  const onResumeSessionClick = () => {
    navigate(kitInstance?.session_url)
  }

  const onResultsSessionClick = () => {
    navigate(kitInstance?.results_url)
  }

  const onReopenSessionClick = async () => {
    await restartKitSessionTestOnly({ kitInstanceId: kitInstance.id })
    await startKitSession({ kitInstanceId: kitInstance.id })
    navigate(kitInstance.session_url)
  }

  return (
    <View
      className={cn(
        className,
        {
          [miniSubTypeContent.tileBackground]: !kitInfo.unavailable,
          "background-linear-gradient-unavailable": !!kitInfo.unavailable,
        },
        "lift-4",
        "p-small"
      )}
      $alignItems="flex-start"
      $alignContent="flex-start"
      $alignSelf="stretch"
      $radius="8px"
      $flexDirectionMobile="column"
    >
      <View $alignItems="center" $justifyContent="space-between" $alignSelf="stretch">
        {!isScheduleModeActive && !isSetupStep ? (
          <View $flexDirection="column" $justifyContent="space-between" $alignSelf="stretch">
            <View $flexDirection="column" $justifyContent="flex-start" className="full-height">
              <View $gap="8px" $justifyContent="space-between" $alignSelf="stretch" className="text-color-gray-1">
                <h4>{kitInfo.title}</h4>
                <p
                  className={cn(
                    {
                      [miniSubTypeContent.subTypeBackground]: !kitInfo.unavailable,
                      "bg-white": !!kitInfo.unavailable,
                    },
                    "px-xs bg-yellow-tint border-radius-xxl text-xs text-gray-9 text-semi-bold"
                  )}
                >
                  {miniSubTypeContent.title}
                </p>
              </View>
              <p className={cn("text-gray-8", { "mb-medium": !!kitInfo.unavailable })}>
                {!!isGuessingGameMini && (
                  <>
                    <span className="text-orange-4 text-bold">Guessing game</span>
                    {": "}
                  </>
                )}
                {kitInfo.description}
              </p>
              {!!kitInfo.unavailable && (
                <p className="text-gray-8 mt-auto text-center full-width text-italic">
                  Available with <Link to="/subscribe">subscription</Link>
                </p>
              )}
            </View>
            <View $justifyContent="space-between" $alignSelf="stretch">
              <View $justifyContent="flex-start">
                <MiniKitTileActions
                  kitInfo={kitInfo}
                  kitStatus={kitStatus}
                  selectedTeam={selectedTeam}
                  onResumeSessionClick={onResumeSessionClick}
                  onPreviewSessionClick={onPreviewSessionClick}
                  onSessionStartClick={onSessionStartClick}
                  onResultsSessionClick={onResultsSessionClick}
                  onReopenSessionClick={onReopenSessionClick}
                  isCurrentTeamLead={isCurrentTeamLead}
                />
              </View>
              <View $justifyContent="flex-end">
                {kitStatusIcon}
                {/* TODO: Add favorite icon here */}
              </View>
            </View>
          </View>
        ) : (
          <>
            <h4 className="text-gray-9">{kitInfo.title}</h4>
            <p>{kitInfo.description}</p>
          </>
        )}
      </View>
    </View>
  )
})`
  .kit {
    box-shadow: var(--blur-2);

    &:hover {
      box-shadow: var(--lift-4);
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
    h3 {
      max-width: 300px;
    }
  }

  @media (min-width: ${({ theme }) => theme.tabletMin}) {
    h3 {
      max-width: 360px;
    }
  }
`

const MiniKitTileActions = ({
  kitInfo,
  kitStatus,
  selectedTeam,
  onSessionStartClick,
  onPreviewSessionClick,
  onResultsSessionClick,
  onReopenSessionClick,
  onResumeSessionClick,
  isCurrentTeamLead,
}) => {
  const { [FLAGS.REOPEN_SESSION]: reopenSessionFlag } = useFeatures()
  const isDemo = selectedTeam.demo

  if (kitStatus === KIT_STATUS.AVAILABLE) {
    return (
      <>
        {!!isCurrentTeamLead && !selectedTeam?.preview && (
          <Button
            className="secondary mt-medium mb-small p-medium"
            onClick={onSessionStartClick}
            data-testid={`kit-link:${kitInfo.slug}`}
          >
            Start
          </Button>
        )}
        {!!(!!isCurrentTeamLead || isDemo) && (
          <Button
            color={colors.risingBlue}
            className="link inline-block text-normal mt-small"
            onClick={onPreviewSessionClick}
          >
            Preview
          </Button>
        )}
      </>
    )
  } else if (kitStatus === KIT_STATUS.COMPLETE) {
    return (
      <>
        {!!kitInfo.results_pages?.length && (
          <Button
            color={colors.risingBlue}
            className="link inline-block text-normal mb-small mt-small"
            onClick={onResultsSessionClick}
          >
            Results
          </Button>
        )}
        {!!reopenSessionFlag && !!isCurrentTeamLead && (
          <Button
            color={colors.risingBlue}
            className="link inline-block text-normal mb-small mt-small"
            onClick={onReopenSessionClick}
          >
            Reopen
          </Button>
        )}
      </>
    )
  } else if (kitStatus === KIT_STATUS.IN_PROGRESS) {
    return (
      <Button className="secondary mt-medium mb-small p-medium" onClick={onResumeSessionClick}>
        Resume
      </Button>
    )
  }
  return <></>
}

export default MiniKitTile
