import { isEmpty } from "lodash-es"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import ResultFilters from "./ResultFilters"

import { Accordion } from "components"
import TeamDetailsPanel from "components/TeamDetailsPanel"
import { TeamSelector } from "components/TeamSelector"
import { useLatestUserExerciseInstances } from "domains/Exercise/resource"
import SessionPreviewMessage from "domains/LeaderKit/SessionPreviewMessage"
import { getKitTypeInfo } from "domains/LeaderKit/utils"
import getIconOrError from "icons"
import { getResultsUrl, getStandaloneExerciseUrl, useBasicKitInfos, useTeamKitInstances } from "resources/monthly_kit"
import { sendExerciseInvite, sortUsersByShortName, useUser } from "resources/users"
import { Button, View, Loading, Modal, HorizontalRule } from "ui"
import { useSelectedTeam } from "ui/SelectedTeamContext"

const ResultsHome = ({ className }) => {
  const navigate = useNavigate()
  const { selectedTeam } = useSelectedTeam()
  const { data: currentUser } = useUser({ userId: "me" })
  const { data: kitInstances } = useTeamKitInstances({ teamId: selectedTeam?.id })
  const { data: basicKits } = useBasicKitInfos({ teamId: selectedTeam?.id })

  if (!selectedTeam || !kitInstances || !currentUser) {
    return <Loading />
  }

  const startedKitInstances = kitInstances.filter((k) => k.session_started_at)
  const ResultsHomeContent = isEmpty(startedKitInstances) ? NoKitInstancesHomeContent : HomeContent

  const onTeamChange = ({ value }) => {
    navigate(`/team?team_id=${value}`)
  }

  return (
    <View className={className}>
      <div className="main-container full-width">
        <h1 className="text-nowrap">Team Results</h1>
        <ResultsHomeContent
          selectedTeam={selectedTeam}
          currentUser={currentUser}
          startedKitInstances={startedKitInstances}
          basicKits={basicKits}
          onTeamChange={onTeamChange}
        />
      </div>
    </View>
  )
}

const NoKitInstancesHomeContent = ({ selectedTeam, onTeamChange, currentUser }) => (
  <div className="mt-small my-large">
    Once you complete your first session you can access the results of your completed sessions here.
    {!selectedTeam.preview && (
      <>
        {" "}
        <Link to={`/kit?team_id=${selectedTeam.id}`}>Click here</Link> to get started!
      </>
    )}
    {!!selectedTeam.preview && (
      <SessionPreviewMessage className="mt-medium">
        To run a session, first <Link to="/admin?inviteLeads=true">add yourself as a team lead</Link>.
      </SessionPreviewMessage>
    )}
    <TeamDetailsPanel
      TeamSelectorComponent={TeamSelector}
      selectedTeam={selectedTeam}
      onTeamChange={onTeamChange}
      user={currentUser}
    />
    <HorizontalRule margin="mb-small mt-none" height={1} />
  </div>
)

const HomeContent = ({ selectedTeam, currentUser, startedKitInstances, basicKits, onTeamChange }) => {
  const [showInviteErrorModal, setShowInviteErrorModal] = useState(false)

  const meTeamMemberArray = selectedTeam.members.filter((member) => member.id === currentUser.id)
  const otherTeamMembers = selectedTeam.members.filter((member) => member.id !== currentUser.id)
  const sortedTeamMembers = selectedTeam.jumbo
    ? meTeamMemberArray
    : meTeamMemberArray.concat(sortUsersByShortName({ users: otherTeamMembers }))
  const hasIndividualResults = startedKitInstances.some(({ basic_kit }) =>
    basic_kit.results_pages.some((resultsPage) => !resultsPage.team_only)
  )
  const [visibleResults, setVisibleResults] = useState([])

  return (
    <>
      <div className="mt-small my-large">
        Below are the results from the sessions your team completed together.{" "}
        {(!!currentUser.can_manage_billing_for_an_account || !!currentUser.is_a_team_lead) &&
          !currentUser.is_demo_mode_active && (
            <>
              Need to remove someone from your team? Go to your <Link to="/admin">Admin tab</Link>.
            </>
          )}
      </div>
      <TeamDetailsPanel
        TeamSelectorComponent={TeamSelector}
        selectedTeam={selectedTeam}
        onTeamChange={onTeamChange}
        user={currentUser}
      />
      <HorizontalRule margin="mb-small mt-none" height={1} />
      <div className="team-container">
        <ResultFilters
          startedKitInstances={startedKitInstances}
          setVisibleResults={setVisibleResults}
          className="mb-medium text-center"
        />
        <Accordion title="Team summary" isOpen={true}>
          <div className="accordion-container">
            {visibleResults.map((kitInstance) =>
              kitInstance.basic_kit.results_pages
                .filter((resultsPage) => !resultsPage.team_member_only)
                .map((resultsPage) => (
                  <ResultsPageLink key={resultsPage.slug} kitInstance={kitInstance} resultsPage={resultsPage} />
                ))
            )}
          </div>
        </Accordion>
        {!!hasIndividualResults &&
          sortedTeamMembers.map((teamMember) => (
            <Accordion
              title={teamMember.short_name || teamMember.email}
              key={teamMember.id}
              className="text-capitalize"
              isOpen={sortedTeamMembers.length <= 1}
            >
              <TeamMemberSection
                startedKitInstances={visibleResults}
                basicKits={basicKits}
                teamMember={teamMember}
                currentUser={currentUser}
                selectedTeam={selectedTeam}
                setShowInviteErrorModal={setShowInviteErrorModal}
              />
            </Accordion>
          ))}
      </div>
      <Modal
        isOpen={showInviteErrorModal}
        onRequestClose={() => setShowInviteErrorModal(false)}
        size="small"
        content={
          <>
            <div className="py-large">
              Unable to send invite. Please try again, and contact support if the issue persists.
            </div>
            <Button onClick={() => setShowInviteErrorModal(false)}>Close</Button>
          </>
        }
      />
    </>
  )
}

const TeamMemberSection = ({
  startedKitInstances,
  basicKits,
  teamMember,
  currentUser,
  selectedTeam,
  setShowInviteErrorModal,
}) => {
  const { data: exerciseInstances, isFetching } = useLatestUserExerciseInstances({
    teamId: selectedTeam.id,
    userId: teamMember.id,
  })

  if (!exerciseInstances && isFetching) {
    return <Loading />
  }

  const startedExerciseSlugs = exerciseInstances?.map((x) => x.slug) ?? []
  const basicKitSlugs = basicKits?.map((kit) => kit.slug) ?? []

  return (
    <div className="accordion-container">
      {startedKitInstances.map((kitInstance) =>
        kitInstance.basic_kit.results_pages
          .filter((resultsPage) => !resultsPage.team_only)
          .filter((resultsPage) => !resultsPage.curr_user_only || teamMember.id === currentUser.id)
          .map((resultsPage) => (
            <TeamMemberResultsPageLink
              key={resultsPage.slug}
              kitInstance={kitInstance}
              teamMember={teamMember}
              resultsPage={resultsPage}
              startedExerciseSlugs={startedExerciseSlugs}
              basicKitSlugs={basicKitSlugs}
              currentUser={currentUser}
              setShowInviteErrorModal={setShowInviteErrorModal}
              selectedTeam={selectedTeam}
            />
          ))
      )}
    </div>
  )
}

const TeamMemberResultsPageLink = ({
  kitInstance,
  teamMember,
  resultsPage,
  startedExerciseSlugs,
  basicKitSlugs,
  currentUser,
  setShowInviteErrorModal,
  selectedTeam,
}) => {
  const isExerciseStarted = startedExerciseSlugs.includes(kitInstance.basic_kit.exercise.slug)
  const isInBasicKits = basicKitSlugs.includes(kitInstance.slug)
  const showTeamPageTakeExerciseLink =
    isInBasicKits && getKitTypeInfo(kitInstance.basic_kit.type).showTeamPageTakeExerciseLink

  if (!isExerciseStarted) {
    if (!showTeamPageTakeExerciseLink) {
      return null
    }

    return (
      <IncompleteExercise
        exerciseDisplayName={resultsPage.title}
        participantId={teamMember.id}
        currentUser={currentUser}
        kitSlug={kitInstance.slug}
        setShowInviteErrorModal={setShowInviteErrorModal}
        resultsPage={resultsPage}
        selectedTeam={selectedTeam}
      />
    )
  }

  return <ResultsPageLink kitInstance={kitInstance} resultsPage={resultsPage} participantId={teamMember.id} />
}

const IncompleteExercise = ({
  exerciseDisplayName,
  participantId,
  currentUser,
  kitSlug,
  setShowInviteErrorModal,
  resultsPage,
  selectedTeam,
}) => {
  const [inviteSending, setInviteSending] = useState(false)
  const [inviteSent, setInviteSent] = useState(false)
  const Icon = getIconOrError(resultsPage.icon)

  const handleInviteClick = async (userId, slug) => {
    setInviteSending(true)
    try {
      await sendExerciseInvite({ teamId: selectedTeam.id, inviteeId: userId, kitSlug: slug })
      setInviteSending(false)
      setInviteSent(true)
    } catch (error) {
      setInviteSending(false)
      setShowInviteErrorModal(true)
      throw error
    }
  }
  const isSelectedTeamLead = selectedTeam.team_lead_id === currentUser.id

  return (
    <div className="kit-display-wrapper mb-medium text-gray-8">
      <span className="inactive-icon">
        <Icon className="fa-xl" color="var(--gray-6)" />
      </span>
      <View $flexDirection="column">
        <span className="results-page-title">{exerciseDisplayName}</span>
        {participantId === currentUser.id ? (
          <Link
            to={getStandaloneExerciseUrl({ slug: kitSlug, teamId: selectedTeam.id })}
            className="exercise-action-link"
          >
            Take exercise
          </Link>
        ) : isSelectedTeamLead ? (
          inviteSent ? (
            <div className="exercise-action-link">Invite sent!</div>
          ) : inviteSending ? (
            <div className="exercise-action-link">Sending...</div>
          ) : (
            <Button
              onClick={() => handleInviteClick(participantId, kitSlug)}
              className="link text-button exercise-action-link"
            >
              Send invite
            </Button>
          )
        ) : null}
      </View>
    </div>
  )
}

const ResultsPageLink = ({ kitInstance, resultsPage, participantId }) => {
  const Icon = getIconOrError(resultsPage.icon)
  const baseUrl = getResultsUrl({
    slug: kitInstance.slug,
    teamId: kitInstance.team_id,
    resultsPageSlug: resultsPage.slug,
  })
  const url = participantId ? `${baseUrl}&participant=${participantId}` : baseUrl

  return (
    <Link to={url} className="kit-display-wrapper mb-medium text-gray-8">
      <span className="active-icon">
        <Icon className="fa-xl gradient-icon" />
      </span>
      <span className="results-page-title">{resultsPage.title}</span>
    </Link>
  )
}

export default styled(ResultsHome)`
  .title-view {
    color: var(--gray-9);
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: bold;
  }

  .team-container {
    display: grid;
    gap: var(--spacing-3);
  }

  .accordion-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    margin-left: -0.5rem;
  }

  .kit-display-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .results-page-title {
    font-size: 0.938rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  .exercise-action-link {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
  }

  .inactive-icon,
  .active-icon {
    font-size: 1rem;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    margin-right: var(--spacing-0);
  }
`
