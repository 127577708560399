import { Fragment } from "react"
import { Link } from "react-router-dom"

import { Accordion, KalturaVideo, VimeoVideo, YouTubeVideo } from "components"
import * as FONT_AWESOME_ICONS from "icons/FontAwesomeIcons"
import { blockUIComponents, SmallAudioPlayer, LinkModal, Slideshow, Slide, Grid, ShareModal } from "ui"
import PieSliceChart from "ui/PieSliceChart"
import PromoFreeKitLink from "ui/PromoFreeKitLink"
import SmileLegend from "ui/SmileLegend"

const blockComponents = {
  ...blockUIComponents,
  Accordion,
  KalturaVideo,
  SmallAudioPlayer,
  Link,
  LinkModal,
  PieSliceChart,
  PromoFreeKitLink,
  Slideshow,
  Slide,
  SmileLegend,
  VimeoVideo,
  YouTubeVideo,
  Fragment,
  Grid,
  ShareModal,
  ...FONT_AWESOME_ICONS,
}

export default blockComponents
